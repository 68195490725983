import { createRouter, createWebHistory } from 'vue-router'

import MainLayout from '../views/layouts/main-layout/MainLayout'

import { dossierPages } from './dossiers'
import { loginRootPages } from './login/index'
import { screenRootPages } from './screen/index'
import { calendarPages } from './calendar'
import { invoicingPages } from './invoicing'
import { noteRootPages } from '@/router/note'
import { settingsSubPages } from './settings/index'
import { saManagementSubPages } from './sa_management/index'
import { profileRootPages } from './profile/index'

import { contactsRootPages, contactsSubPages } from './contacts/index'
import { wikiRootPages, wikiSubPages } from './wiki/index'
import { tasksSubPages } from './tasks/index'
import { condolencesRootPages } from './condolences/index'
import { partnersRootPages, partnersSubPages } from './partners/index'
import { statisticsRootPages, statisticsSubPages } from '@/router/statistics'
import { chatRootPages } from '@/router/chat'
import { supplierOrderSubPages } from '@/router/supplierOrder'
import { versionNoteRootPages } from '@/router/versionNote'
import { templateDashboardSubPages } from '@/router/templateDashboard'
import { modelNotificationRootPages } from '@/router/modelNotification'
import { electronicSignatureRootPages } from '@/router/electronicSignature'
import { stockSubPages } from '@/router/stock'
import { catalogRootPages } from '@/router/catalog/catalog'
import { marketplacePages } from '@/router/marketplace'
import { personRootPages } from '@/router/person'

const routes = [
  ...loginRootPages,
  ...screenRootPages,
  {
    path: '/',
    name: 'root',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'root_base',
        //rendre ici la redirection dynamique.. store.getters['auth/getAuth']['settings'][landing_page]]
        redirect: { name: 'dashboard_dossier_main_page_route' }
      },
      ...[
        { ...condolencesRootPages },
        { ...contactsRootPages },
        { ...noteRootPages },
        { ...partnersRootPages },
        { ...noteRootPages },
        { ...profileRootPages },
        { ...statisticsRootPages },
        { ...wikiRootPages }
      ],
      ...marketplacePages,
      ...personRootPages,
      ...calendarPages,
      ...chatRootPages,
      ...contactsSubPages,
      ...dossierPages,
      ...invoicingPages,
      ...modelNotificationRootPages,
      ...partnersSubPages,
      ...saManagementSubPages,
      ...settingsSubPages,
      ...statisticsSubPages,
      ...supplierOrderSubPages,
      ...tasksSubPages,
      ...templateDashboardSubPages,
      ...chatRootPages,
      ...versionNoteRootPages,
      ...catalogRootPages,
      ...wikiSubPages,
      ...electronicSignatureRootPages,
      ...stockSubPages
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition
    }
    return { left: 0, top: 0 }
  }
})

export default router
