const TheWikiCityMunicipalAdministrationFormTabComponent = () =>
  import(
    '@/views/wiki/communes/form/tabs/municipalAdministration/form/TheWikiCityMunicipalAdministrationFormTabComponent.vue'
  )
const WikiCityMunicipalAdministrationInformations = () =>
  import(
    '@/views/wiki/communes/form/tabs/municipalAdministration/form/tabs/informations/WikiCityMunicipalAdministrationInformations.vue'
  )
const WikiCityMunicipalAdministrationOpeningTime = () =>
  import(
    '@/views/wiki/communes/form/tabs/municipalAdministration/form/tabs/openingTime/WikiCityMunicipalAdministrationOpeningTime.vue'
  )
const WikiPlaceItemsTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue')
const WikiCityMunicipalAdministrationIndexPage = () =>
  import(
    '@/views/wiki/communes/form/tabs/municipalAdministration/index/WikiCityMunicipalAdministrationIndexPage.vue'
  )
const WikiPlaceContactTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue')
const WikiPlaceTranslationTab = () =>
  import(
    '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )

export const wikiCityMunicipalAdministrationRoutes = [
  {
    path: 'municipal-administration',
    children: [
      {
        path: '',
        name: 'wiki_city_municipal_administration_index_page_route',
        props: true,
        component: WikiCityMunicipalAdministrationIndexPage
      },
      {
        path: ':administration_id',
        name: 'wiki_city_edit_municipal_administration_page_route',
        component: TheWikiCityMunicipalAdministrationFormTabComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_municipal_administration_information_page_route',
            component: WikiCityMunicipalAdministrationInformations,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_municipal_administration_translation_page_route',
            component: WikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'opening-time',
            name: 'wiki_city_edit_municipal_administration_opening_time_page_route',
            component: WikiCityMunicipalAdministrationOpeningTime,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_municipal_administration_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_edit_municipal_administration_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_municipal_administration_page_route',
        component: TheWikiCityMunicipalAdministrationFormTabComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_municipal_administration_information_page_route',
            component: WikiCityMunicipalAdministrationInformations,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_create_municipal_administration_translation_page_route',
            component: WikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'opening-time',
            name: 'wiki_city_create_municipal_administration_opening_time_page_route',
            component: WikiCityMunicipalAdministrationOpeningTime,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_create_municipal_administration_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_create_municipal_administration_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
