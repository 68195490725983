<template>
  <div class="flex flex-col gap-x-2">
    <h3 v-if="label" class="text-lg font-bold text-gray-700 capitalize-first">
      {{ label }}
    </h3>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoicingPrintLeftMenuSection',
  props: {
    label: {
      type: String,
      required: false
    }
  }
}
</script>
