<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-row items-center gap-2">
      <span class="shrink-0">
        <BaseButton
          icon="chevron-left"
          size="extra-small"
          @click="() => (localCurrentView = 'actions')"
        />
      </span>
      <h2 class="text-xl font-semibold">{{ $t('attributes.header') }}</h2>
    </div>

    <div class="grid grid-cols-2 gap-2">
      <div
        class="flex cursor-pointer flex-col gap-2 rounded border p-3"
        :class="
          !header
            ? 'border-primary-400 bg-primary-50 shadow shadow-primary-300'
            : ''
        "
        @click="$emit('update:header', null)"
      >
        <div>
          <div
            class="flex aspect-A4 items-center justify-center rounded border bg-primary-100"
          >
            <FontAwesomeIcon :icon="['fal', 'times']" size="4x" />
          </div>
        </div>
        <div class="truncate text-center font-semibold">blank</div>
      </div>
      <div
        v-for="headerItem in headerList"
        :key="headerItem.id"
        class="flex cursor-pointer flex-col gap-2 rounded border p-3"
        :class="
          headerItem[trackBy] === header
            ? 'border-primary-400 bg-primary-50 shadow shadow-primary-300'
            : ''
        "
        @click="$emit('update:header', headerItem[trackBy])"
      >
        <div>
          <img
            class="aspect-A4 rounded border bg-white"
            :src="headerItem.preview_url"
            :alt="headerItem.name"
          />
        </div>
        <div class="truncate text-center font-semibold">
          {{ headerItem.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default defineComponent({
  name: 'HeaderSelector',
  components: { BaseButton, FontAwesomeIcon },
  props: {
    authorizeBlank: {
      type: Boolean,
      required: false,
      default: true
    },
    headerList: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    currentView: {
      type: String,
      required: true
    }
  },
  emits: ['update:header', 'update:currentView', 'change'],
  computed: {
    localCurrentView: {
      get() {
        return this.currentView
      },
      set(value) {
        this.$emit('update:currentView', value)
        this.$emit('change')
      }
    }
  }
})
</script>
