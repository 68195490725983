<template>
  <div class="flex flex-col gap-4 p-4">
    <PageSection :title="$t('global.informations')">
      <div class="flex flex-col divide-y">
        <div class="grid-form pb-4">
          <div v-if="payment_mode.denomination">
            <BaseShowEditTranslation
              v-model:translations="payment_mode.denomination"
              edition-mode
              :label="$t('attributes.denomination')"
              :required="true"
              :errors="errors?.denomination?.[$i18n.locale]"
            />
          </div>
          <div>
            <SelectComponent
              v-model="payment_mode.bank_account_id"
              name="bank_account_id"
              :label="'comptes bancaires'"
              :values="bank_accounts"
              required
              colored
            />
          </div>
        </div>
        <div class="grid-form py-4">
          <SelectFuneralHome
            multiple
            class="col-start-1"
            v-model="payment_mode.funeral_homes"
            edition-mode
            error-key="funeral_homes"
          />
          <div
            v-if="payment_mode.funeral_homes?.length > 1"
            class="col-span-2 col-start-1 gap-2"
          >
            <BadgeItemComponent
              v-for="funeral_home in payment_mode.funeral_homes"
              :key="funeral_home"
              list="funeral_homes"
              :badge-element="funeral_home"
              :color="
                funeral_homes.find((item) => item.id === funeral_home)?.color
              "
              :badge-text="
                funeral_homes.find((item) => item.id === funeral_home)?.name
              "
              @remove-badge="handleRemoveBadge"
            />
          </div>
        </div>
        <div class="grid-form py-4">
          <SelectComponent
            v-model="payment_mode.doc_types"
            name="doc_types"
            class="col-start-1"
            :label="$t('global.document')"
            :values="invoicing_doc_types"
            required
            colored
            multiple
          />
          <div
            v-if="payment_mode.doc_types?.length > 1"
            class="col-span-2 col-start-1 gap-2"
          >
            <BadgeItemComponent
              v-for="doc_type in payment_mode.doc_types"
              :key="doc_type"
              list="doc_types"
              :badge-element="doc_type"
              :color="
                invoicing_doc_types.find((item) => item.id === doc_type)?.color
              "
              :badge-text="
                invoicing_doc_types.find((item) => item.id === doc_type)?.name
              "
              @remove-badge="handleRemoveBadge"
            />
          </div>
        </div>
        <div class="grid-form py-4">
          <SelectComponent
            v-model="payment_mode.doc_roles"
            name="doc_types"
            class="col-start-1"
            :label="'rôles du document'"
            :values="invoicing_doc_roles"
            required
            colored
            multiple
          />
          <div
            v-if="payment_mode.doc_roles?.length > 1"
            class="col-span-2 col-start-1 gap-2"
          >
            <BadgeItemComponent
              v-for="doc_role in payment_mode.doc_roles"
              :key="doc_role"
              list="doc_roles"
              :badge-element="doc_role"
              :color="
                invoicing_doc_roles.find((item) => item.id === doc_role)?.color
              "
              :badge-text="
                invoicing_doc_roles.find((item) => item.id === doc_role)?.name
              "
              @remove-badge="handleRemoveBadge"
            />
          </div>
        </div>
        <div class="grid-form pt-4">
          <SelectComponent
            class="col-start-1"
            v-model="payment_mode.payment_modes"
            name="doc_types"
            :label="$t('navigation.content_payment_mode_title')"
            :values="payment_modes"
            required
            colored
            multiple
          />
          <div
            v-if="payment_mode.payment_modes?.length > 1"
            class="col-span-2 col-start-1 gap-2"
          >
            <BadgeItemComponent
              v-for="paymentMode in payment_mode.payment_modes"
              :key="paymentMode"
              list="payment_modes"
              :badge-element="paymentMode"
              :bg-color="
                payment_modes.find((item) => item.id === paymentMode)?.bg_color
              "
              :badge-text="
                payment_modes.find((item) => item.id === paymentMode)?.name
              "
              @remove-badge="handleRemoveBadge"
            />
          </div>
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import PageSection from '@c/PageSection.vue'
import { mapGetters } from 'vuex'
import BaseShowEditTranslation from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTranslation.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import BadgeItemComponent from '@/views/settings/invoicing/PaymentMode/form/tabs/components/BadgeItemComponent.vue'

export default {
  name: 'ThePaymentInformationPage',
  components: {
    BadgeItemComponent,
    SelectFuneralHome,
    SelectComponent,
    BaseShowEditTranslation,
    PageSection
  },
  computed: {
    ...mapGetters({
      payment_mode: 'paymentMode/getPaymentMode',
      errors: 'auth/getErrors',
      payment_modes: 'metadata/getPaymentModesMetadata',
      invoicing_doc_roles: 'metadata/getInvoiceDocRoles',
      invoicing_doc_types: 'metadata/getInvoiceDocTypes',
      funeral_homes: 'metadata/getFuneralHomesMetadata',
      bank_accounts: 'metadata/getBankAccountsMetadata'
    })
  },
  methods: {
    handleRemoveBadge(payload) {
      this.payment_mode[payload.list] = this.payment_mode[payload.list].filter(
        (elt) => elt !== payload.element
      )
    }
  }
}
</script>
