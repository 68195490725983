const TheSiteIndexPage = () =>
  import('@/views/settings/corporation/site/index/TheSiteIndexPage')
const TheSiteFormsTabsComponent = () =>
  import('@/views/settings/corporation/site/form/TheSiteFormsTabsComponent')
const TheSiteInformationPage = () =>
  import(
    '@/views/settings/corporation/site/form/tabs/Information/TheSiteInformationPage'
  )
const TheSiteActivityPage = () =>
  import(
    '@/views/settings/corporation/site/form/tabs/activities/TheSiteActivityPage'
  )
const TheSiteLocationPage = () =>
  import(
    '@/views/settings/corporation/site/form/tabs/siteLocation/TheSiteLocationPage'
  )

export const settingsSiteRoutes = [
  {
    path: 'site',
    children: [
      {
        path: '',
        name: 'settings_list_site_route',
        component: TheSiteIndexPage,
        meta: {
          type: 'index',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_site_information_route',
        props: true,
        component: TheSiteFormsTabsComponent,
        children: [
          {
            path: '',
            component: TheSiteInformationPage,
            name: 'settings_edit_site_route'
          },
          {
            path: 'locations',
            component: TheSiteLocationPage,
            name: 'settings_edit_site_locations_route'
          },
          {
            path: 'activities',
            component: TheSiteActivityPage,
            props: true,
            name: 'settings_edit_site_activity_route'
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_site_info_route',
        component: TheSiteFormsTabsComponent,
        children: [
          {
            path: '',
            component: TheSiteInformationPage,
            name: 'settings_create_site_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_implantation_title', active: false },
            { title: 'content_implantation_new_title', active: true }
          ]
        }
      }
    ]
  }
]
