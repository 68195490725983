<template>
  <BaseCenteredModal
    v-model:is-modal-open="localVisible"
    :title="$t('button.filter')"
    :click-outside-close="true"
    :size="'medium'"
  >
    <template #body>
      <div class="w-full flex flex-row gap-[15px] flex-wrap pt-3 px-1">
        <div class="block" style="width: 100%">
          <BaseInput
            v-model="localSearch"
            name="search"
            :label="$t('button.type_to_search')"
            :disabled="disabled"
          />
        </div>

        <div class="block" style="width: calc(50% - 7.5px)">
          <SelectComponent
            v-model="localLang"
            name="lang"
            :label="$t('attributes.language')"
            :edition-mode="true"
            :values="languages"
            :required="true"
            :disabled="disabled"
          />
        </div>

        <div class="block" style="width: calc(50% - 7.5px)">
          <SelectComponent
            v-model="localFuneralHome"
            name="funeralHomeId"
            :label="$t('attributes.funeralHome')"
            :edition-mode="true"
            :values="funeralHomes"
            :disabled="disabled"
          />
        </div>

        <div class="block" style="width: calc(50% - 7.5px)">
          <SelectComponent
            v-model="localItemCategory"
            name="itemCategoryId"
            :label="$t('attributes.item_category_id')"
            :edition-mode="true"
            :values="itemsCategories"
            :disabled="disabled"
          />
        </div>

        <div class="block" style="width: calc(50% - 7.5px)">
          <SelectComponent
            v-model="localSupplier"
            name="supplierId"
            :label="$t('attributes.supplier')"
            :edition-mode="true"
            :values="suppliers"
            :disabled="disabled"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex items-center justify-end">
        <BaseButton
          :title="$t('button.filter')"
          color="warning"
          size="medium"
          icon="sliders"
          @click.prevent="reloadItems"
        />
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseInput from '@c/addf-package/components/BaseInput/BaseInput.vue'

export default {
  name: 'ItemsExcelFilterModal',
  components: { BaseInput, BaseButton, SelectComponent, BaseCenteredModal },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    lang: {
      type: String,
      required: true
    },
    funeralHome: {
      type: String,
      required: true
    },
    itemCategory: {
      type: String,
      required: true
    },
    supplier: {
      type: String,
      required: true
    },
    search: {
      type: String,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    funeralHomes: {
      type: Array,
      required: true
    },
    itemsCategories: {
      type: Array,
      required: true
    },
    suppliers: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localLang: this.lang,
      localFuneralHome: this.funeralHome,
      localItemCategory: this.itemCategory,
      localSupplier: this.supplier,
      localSearch: this.search
    }
  },
  emits: [
    'update:visible',
    'update:language',
    'update:funeral-home',
    'update:item-category',
    'update:supplier',
    'update:search',
    'reload:items'
  ],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  methods: {
    reloadItems() {
      let canChange =
        this.funeralHome !== this.localFuneralHome ||
        this.itemCategory !== this.localItemCategory ||
        this.supplier !== this.localSupplier

      this.$emit('update:lang', this.localLang)
      this.$emit('update:funeral-home', this.localFuneralHome)
      this.$emit('update:item-category', this.localItemCategory)
      this.$emit('update:supplier', this.localSupplier)
      this.$emit('update:search', this.localSearch)

      setTimeout(() => {
        this.$emit('reload:items', canChange)
      }, 300)
    }
  }
}
</script>
