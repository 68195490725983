const TheMaterialResourceIndexPage = () =>
  import(
    '@/views/settings/corporation/materialResource/index/TheMaterialResourceIndexPage.vue'
  )
const TheMaterialResourceFormsTabComponent = () =>
  import(
    '@/views/settings/corporation/materialResource/form/TheMaterialResourceFormsTabComponent.vue'
  )
const TheMaterialResourceInformationPage = () =>
  import(
    '@/views/settings/corporation/materialResource/form/tab/materialResourceInformation/TheMaterialResourceInformationPage.vue'
  )
const TheMaterialResourceGalleryPage = () =>
  import(
    '@/views/settings/corporation/materialResource/form/tab/materialResourceGallery/TheMaterialResourceGalleryPage.vue'
  )
const TheMaterialResourceActivityPage = () =>
  import(
    '@/views/settings/corporation/materialResource/form/tab/materialResourceActivity/TheMaterialResourceActivityPage.vue'
  )

export const settingsMaterialResourceRoutes = [
  {
    path: 'material-resource',
    children: [
      {
        path: '',
        name: 'settings_list_material_resource_route',
        component: TheMaterialResourceIndexPage,
        meta: {
          type: 'index',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_material_resource_information_route',
        props: true,
        component: TheMaterialResourceFormsTabComponent,
        children: [
          {
            path: '',
            component: TheMaterialResourceInformationPage,
            name: 'settings_edit_material_resource_route'
          },
          {
            path: 'gallery',
            component: TheMaterialResourceGalleryPage,
            props: true,
            name: 'settings_edit_material_resource_gallery_route'
          },
          {
            path: 'activities',
            component: TheMaterialResourceActivityPage,
            name: 'settings_edit_material_resource_activity_route',
            props: true
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_material_resource_info_route',
        component: TheMaterialResourceFormsTabComponent,
        children: [
          {
            path: '',
            component: TheMaterialResourceInformationPage,
            name: 'settings_create_material_resource_route'
          },
          {
            path: 'gallery',
            component: TheMaterialResourceGalleryPage,
            props: true,
            name: 'settings_create_material_resource_gallery_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_implantation_title', active: false },
            { title: 'content_implantation_new_title', active: true }
          ]
        }
      }
    ]
  }
]
