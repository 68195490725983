const ProfilePage = () => import('@/views/profile/profilePage')

export const profileRootPages = {
  path: '/profile',
  name: 'profile_form_route',
  redirect: {
    name: 'profile_information_route'
  },
  children: [
    {
      path: '',
      component: ProfilePage,
      name: 'profile_information_route',
      props: {
        currentView: 'information'
      }
    },
    {
      path: 'notification',
      component: ProfilePage,
      name: 'profile_notification_route',
      props: {
        currentView: 'notification'
      }
    }
  ]
}
