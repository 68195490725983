export default {
  setDocumentsList(state, documentsList) {
    state.documentsList = documentsList
  },
  setSharedDocumentsList(state, documentsList) {
    state.sharedDocumentsList = documentsList
  },
  setDocumentsHeaders(state, headers) {
    state.documentsHeaders = headers
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setDocument(state, document) {
    if (!document.funeral_homes) {
      document.funeral_homes = []
    }
    state.document = document
  },
  alterDocumentQuestionValue(state, payload) {
    state.document.form[payload.index].value = payload.value
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  setDocumentActivities(state, documentActivities) {
    state.documentActivities = documentActivities
  },
  setDocumentTemplate(state, file) {
    state.document.template = file

    // if (!state.document.template_extension) {
    //   state.document.template_extension = file.name.substring(
    //     file.name.lastIndexOf('.')
    //   )
    // }

    if (!state.document.template_name) {
      state.document.template_name = file.name.substring(
        0,
        file.name.lastIndexOf('.')
      )
    }
  },
  addFormFieldToDocument(state, payload) {
    if (!state.document.form) {
      state.document.form = []
    }
    state.document.form.push(payload)
  },
  deleteFormFieldFromDocument(state, payload) {
    state.document.form.splice(payload.index, 1)
  }
}
