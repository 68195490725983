import apiClient from '@u/apiClient'

let base_errors = { deceased: {}, events: [] }

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  addModelActivity({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post('model-activity', payload.activity)
        .then((response) => {
          resolve(response.data)
          if(response.data.metadata) {
            commit('invoicing/setInvoiceMetadata', response.data.metadata, { root: true })
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
}
