const WikiCityHospitalFormsTabsComponent = () =>
  import(
    '@/views/wiki/communes/form/tabs/hospital/form/WikiCityHospitalFormsTabsComponent.vue'
  )
const wikiCityHospitalInformation = () =>
  import(
    '@/views/wiki/communes/form/tabs/hospital/form/tabs/information/WikiCityHospitalInformation.vue'
  )
const WikiCityHospitalIndexPage = () =>
  import(
    '@/views/wiki/communes/form/tabs/hospital/index/WikiCityHospitalIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue')
const WikiPlaceContactTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue')
const wikiPlaceTranslationTab = () =>
  import(
    '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )

export const wikiCityHospitalRoutes = [
  {
    path: 'hospital',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_hospital_index_page_route',
        props: true,
        component: WikiCityHospitalIndexPage,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':hospital_id',
        name: 'wiki_city_edit_hospital_page_route',
        component: WikiCityHospitalFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_hospital_information_page_route',
            component: wikiCityHospitalInformation,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_hospital_translation_page_route',
            component: wikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_hospital_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_edit_hospital_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_hospital_page_route',
        props: true,
        component: WikiCityHospitalFormsTabsComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_hospital_information_page_route',
            component: wikiCityHospitalInformation,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_create_hospital_translation_page_route',
            component: wikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_create_hospital_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_create_hospital_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
