import { wikiCityRoutes } from './city/index'
import { wikiLocationsRoute } from './locations/index'

const WorkInProgressComponent = () =>
  import('@/views/wip/WorkInProgressComponent')
const TheWikiCityIndexPage = () =>
  import('@/views/wiki/communes/index/TheWikiCityIndexPage.vue')
const TheWikiMapPage = () =>
  import('@/views/wiki/communes/map/TheWikiMapPage.vue')

export const wikiRootPages = {
  path: 'wiki',
  name: 'wiki_route',
  component: WorkInProgressComponent
}

export const wikiSubPages = [
  {
    path: 'wiki',
    children: [
      {
        path: 'city',
        children: [
          {
            path: '',
            name: 'wiki_city_page_route',
            component: TheWikiCityIndexPage
          },
          ...wikiCityRoutes
        ]
      },
      {
        path: 'map',
        children: [
          {
            path: '',
            name: 'route_base_map',
            component: TheWikiMapPage,
            props: {
              placeType: null,
              placeId: null
            }
          },
          {
            path: 'place/:placeId',
            component: TheWikiMapPage,
            name: 'route_place_id',
            props: (route) => {
              return {
                placeType: 'place',
                ...route.params
              }
            }
          }
        ]
      }
    ]
  },
  ...wikiLocationsRoute
]
