import moment from 'moment'
import 'moment/dist/locale/fr-ch'
import 'moment/dist/locale/nl-be'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/ro'
import i18n from '@src/i18n'
import { formatDate } from '@fullcalendar/core'

export default {
  getRelativeDateAsArray(period) {
    if (period === 'today') {
      return [moment(), moment()]
    } else if (period === 'tomorrow') {
      return [moment().add(1, 'day'), moment().add(1, 'day')]
    } else if (period === 'yesterday') {
      return [moment().add(-1, 'day'), moment().add(-1, 'day')]
    } else if (period === 'these_3_days') {
      return [moment(), moment().add(2, 'days')]
    } else if (period === 'next_3_days') {
      return [moment().add(1, 'day'), moment().add(3, 'days')]
    } else if (period === 'these_5_days') {
      return [moment(), moment().add(4, 'days')]
    } else if (period === 'next_5_days') {
      return [moment().add(1, 'day'), moment().add(5, 'days')]
    } else if (period === 'these_7_days') {
      return [moment(), moment().add(6, 'days')]
    } else if (period === 'next_7_days') {
      return [moment().add(1, 'day'), moment().add(7, 'days')]
    } else if (period === 'last_3_days') {
      return [moment().add(-2, 'days'), moment()]
    } else if (period === 'last_5_days') {
      return [moment().add(-4, 'days'), moment()]
    } else if (period === 'this_week') {
      return [moment().startOf('week'), moment().endOf('week')]
    } else if (period === 'last_week') {
      return [
        moment().startOf('week').add(-1, 'week'),
        moment().endOf('week').add(-1, 'week')
      ]
    } else if (period === 'next_week') {
      return [
        moment().startOf('week').add(1, 'week'),
        moment().endOf('week').add(1, 'week')
      ]
    } else if (period === 'last_7_days') {
      return [moment().add(-6, 'days'), moment()]
    } else if (period === 'this_month') {
      return [moment().startOf('month'), moment().endOf('month')]
    } else if (period === 'last_month') {
      return [
        moment().add(-1, 'month').startOf('month'),
        moment().add(-1, 'month').endOf('month')
      ]
    } else if (period === 'next_month') {
      return [
        moment().add(1, 'month').startOf('month'),
        moment().add(1, 'month').endOf('month')
      ]
    } else if (period === 'last_30_days') {
      return [moment().add(-29, 'days'), moment()]
    } else if (period === 'these_30_days') {
      return [moment(), moment().add(29, 'days')]
    } else if (period === 'next_30_days') {
      return [moment().add(1, 'day'), moment().add(30, 'days')]
    } else if (period === 'this_quarter') {
      return [moment().startOf('quarter'), moment().endOf('quarter')]
    } else if (period === 'last_quarter') {
      return [
        moment().add(-1, 'quarter').startOf('quarter'),
        moment().add(-1, 'quarter').endOf('quarter')
      ]
    } else if (period === 'next_quarter') {
      return [
        moment().add(1, 'quarter').startOf('quarter'),
        moment().add(1, 'quarter').endOf('quarter')
      ]
    } else if (period === 'last_90_days') {
      return [moment().add(-89, 'days'), moment()]
    } else if (period === 'these_90_days') {
      return [moment(), moment().add(89, 'days')]
    } else if (period === 'next_90_days') {
      return [moment().add(1, 'day'), moment().add(90, 'days')]
    } else if (period === 'this_year') {
      return [moment().startOf('year'), moment().endOf('year')]
    } else if (period === 'last_year') {
      return [
        moment().add(-1, 'year').startOf('year'),
        moment().add(-1, 'year').endOf('year')
      ]
    } else if (period === 'next_year') {
      return [
        moment().add(1, 'year').startOf('year'),
        moment().add(1, 'year').endOf('year')
      ]
    } else if (period === 'last_365_days') {
      return [moment().add(-364, 'days'), moment()]
    } else if (period === 'these_365_days') {
      return [moment(), moment().add(364, 'days')]
    } else if (period === 'next_365_days') {
      return [moment().add(1, 'day'), moment().add(365, 'days')]
    }
  },
  getRelativeDateString(period, format, locale) {
    if (format === undefined) {
      format = 'LL'
    }
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    let [start, end] = this.getRelativeDateAsArray(period)
    if (start.isSame(end, 'day')) {
      return start.locale(locale).format(format)
    }
    return `${start.locale(locale).format(format)} - ${end
      .locale(locale)
      .format(format)}`
  },
  getNumberOfDaysBetweenDates(start, end) {
    let startm = moment(start)
    let endm = moment(end)
    return endm.diff(startm, 'days')
  },
  getDateRangeString(start, end, format, locale) {
    if (format === undefined) {
      format = 'LL'
    }
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    let startm = moment(start)
    let endm = moment(end)
    if (startm.isSame(endm, 'day')) {
      return startm.locale(locale).format(format)
    }
    return `${startm.locale(locale).format(format)} - ${endm
      .locale(locale)
      .format(format)}`
  },
  _getCurrentLocaleForMomentJs() {
    switch (i18n.vueI18n.global.locale) {
      case 'fr_BE':
      case 'fr_FR':
        return 'fr'
      case 'fr_CH':
        return 'fr-ch'
      case 'nl_BE':
        return 'nl-be'
      case 'nl_NL':
        return 'nl'
      default:
        return 'en'
    }
  },
  formatDate(date, format, locale) {
    locale = locale !== undefined ? locale : this._getCurrentLocaleForMomentJs()
    let _moment = moment(date)
    return _moment.isValid() ? _moment.locale(locale).format(format) : ''
  },
  momentLocalised(date, format, locale) {
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    return moment(date).locale(locale).format(format)
  },
  durationHumanized(duration, locale, units) {
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    if (units === undefined) {
      units = 'minutes'
    }
    return moment.duration(duration, units).locale(locale).humanize()
  },
  formatDateAndDiff(date, format, locale) {
    locale = locale !== undefined ? locale : this._getCurrentLocaleForMomentJs()
    return `${this.formatDate(date, format, locale)} (${moment(date)
      .locale(locale)
      .fromNow()})`
  }
}
