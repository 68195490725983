<template>
  <div class="h-full flex-1 overflow-hidden rounded-xl bg-white">
    <SettingsPageFull>
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">
            {{ $t('navigation.version_notes_title') }}
          </span>
        </div>
      </template>
      <template #body>
        <div class="flex min-h-0 flex-1 flex-col gap-5 overflow-y-auto">
          <ReleaseComponent
            v-for="release in releases"
            :key="release.id"
            :release="release"
          />
        </div>
      </template>
    </SettingsPageFull>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import SettingsPageFull from '@c/SettingsPageFull'
import VersionCardComponent from '@ui/modal/versionNote/VersionCardComponent.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ReleaseComponent from '@/views/settings/versionNote/component/ReleaseComponent.vue'

export default {
  name: 'TheVersionNoteIndexPage',
  components: {
    ReleaseComponent,
    BaseButton,
    VersionCardComponent,
    SettingsPageFull
  },
  data() {
    return {
      releases: [],
      setReleaseVersionTimeout: null
    }
  },
  mounted() {
    this.processFetchAllVersionNotesAction().then((response) => {
      this.releases = response.releases
      this.setReleaseVersionTimeout = setTimeout(() => {
        this.setReleaseNoteAction(response.current_version)
      }, 5000)
    })
  },
  unmounted() {
    clearTimeout(this.setReleaseVersionTimeout)
  },
  methods: {
    ...mapActions({
      processFetchAllVersionNotesAction: 'versionNote/fetchAllVersionNotes',
      setReleaseNoteAction: 'auth/updateUserReleaseNoteVersion'
    })
  }
}
</script>
