const TheMailBoxComposePage = () =>
  import('@/views/modelNotification/form/TheMailBoxComposePage.vue')
const ThreadListComponent = () =>
  import('@/views/modelNotification/index/components/ThreadListComponent.vue')
const ThreadComponent = () =>
  import('@/views/modelNotification/index/components/ThreadComponent.vue')
const TheMailBoxIndexPage = () =>
  import('@/views/modelNotification/index/TheMailBoxIndexPage.vue')
const TheMailBoxShowPage = () =>
  import('@/views/modelNotification/show/TheMailBoxShowPage')

import mailboxTypes from '@/assets/enums/notifications/mailboxTypes'

export const modelNotificationRootPages = [
  {
    path: 'mailbox',
    name: 'mail_box_page_route',
    redirect: { name: 'mail_box_page_path', params: { path: 'inbox' } },
    component: TheMailBoxIndexPage,
    meta: {
      type: 'list'
    },
    children: [
      {
        path: '',
        redirect: { name: 'mail_box_page_path', params: { path: 'inbox' } },
        name: 'mail_box_page'
      },
      {
        path: 'compose',
        name: 'mail_box_compose_route',
        component: TheMailBoxComposePage,
        props: true,
        children: [
          {
            path: '',
            name: 'mail_box_create_route',
            props: true
          },
          {
            path: ':message_id',
            name: 'mail_box_edit_route',
            props: true
          }
        ]
      },
      {
        path: ':path',
        name: 'mail_box_page_path',
        props: true,
        component: ThreadListComponent,
        beforeEnter: (to, from, next) => {
          if (Object.values(mailboxTypes).includes(to.params.path)) {
            next()
            return
          } else {
            console.log('path doest not exist')
          }
        },
        children: [
          {
            path: ':groupId',
            name: 'mail_box_page_group_id',
            props: true,
            component: ThreadComponent
          }
        ]
      }
    ]
  }
]
