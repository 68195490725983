import { mapActions, mapGetters, mapMutations } from 'vuex'
import cash from 'cash-dom/dist/cash'

export default {
  computed: {
    ...mapGetters({
      entries: 'item/getItemList',
      item: 'item/getSelectedItem',
      initialMetadata: 'metadata/getInitialMetadata',
      metadata: 'item/getItemMetadata',
      errors: 'auth/getErrors',
      editionMode: 'item/getEditionMode',
      getDefaultCountry: 'metadata/getDefaultCountry'
    }),
    orderedItemTypes() {
      return this.initialMetadata?.enums?.item_types.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    orderedItemCategories() {
      return this.initialMetadata?.models?.item_categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    isDefaultCountryFr() {
      return this.getDefaultCountry === 'FR'
    }
  },
  methods: {
    ...mapMutations({
      processSetEditionModeMutation: 'item/setEditionMode',
      processSetItemMutation: 'item/setItem',
      processSetItemNewMasterImageFileMutation: 'item/setItemNewMasterImageFile'
    }),
    ...mapActions({
      fetchAllItem: 'item/fetchAllItem',
      processFetchItemByIdAction: 'item/fetchItemByID',
      processFetchNewItemAction: 'item/fetchNewItem',
      processStoreItemAction: 'item/storeNewItem',
      processUpdateItemAction: 'item/updateItem',
      processCreateItemVarianteAction: 'item/createItemVariante',
      deleteItemById: 'item/deleteItemById',
      undoDeleteItemById: 'item/undoDeleteItemById'
    }),
    redirectToModelPage(id) {
      this.$router.push({
        name: 'settings_edit_item_route',
        params: { id: id }
      })
    },
    deleteItem(id) {
      this.deleteItemById({
        id: id,
        successCallback: () => {
          const toast = cash('#notification-with-actions-content').clone()
          toast.find('#undo_btn').on('click', () => {
            this.undoDeleteItem(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass('hidden')[0]
          })
        }
      })
    },
    undoDeleteItem(id) {
      this.undoDeleteItemById({
        id: id,
        successCallback: () => {
          this.$h.toastify(
            this.$t('toasts.reseted_v', { var: this.$t('attributes.item') }),
            { className: 'toastify-content success' }
          )
        }
      })
    },
    redirectToEditableModelPage(id) {
      this.processSetEditionModeMutation(true)
      this.$router.push({
        name: 'settings_edit_item_route',
        params: { id: id }
      })
    },

    fetchAndRedirectToItemPage(arg) {
      this.processFetchItemByIdAction({ id: arg })
      this.$router.push({
        name: 'settings_edit_item_route',
        params: { id: arg }
      })
    },
    handleMasterAvatarChange(file) {
      this.processSetItemNewMasterImageFileMutation(file)
    }
  }
}
