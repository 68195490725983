import apiClient from '@u/apiClient'
import Promise from 'lodash-es/_Promise'

let base_errors = { deceased: {}, events: [] }

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  async fetchWikiAddressesByCity({ commit }, payload) {
    await apiClient
      .get(`wiki-address/by-city/${payload.city_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('address/setAddressList', response.data.addresses, {
            root: true
          })
        }
      })
      .catch(async (reason) => {
        await console.error('', reason)
      })
  },
  async fetchWikiAddressesById({ commit }, payload) {
    await apiClient
      .get(`wiki-address/${payload.address_id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('address/setAddress', response.data.address, { root: true })
        }
      })
      .catch(async (reason) => {
        await console.error('fetchWikiAddressesById', reason)
      })
  },
  storeWikiAddress: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`wiki-address`, payload.address)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateWikiAddress: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`wiki-address/${payload.address_id}`, payload.address)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchWikiAddressesByCityId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`wiki-address/by-city/${payload.city_id}`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
