const wikiCityRestHomeFormsTabsComponent = () =>
  import(
    '@/views/wiki/communes/form/tabs/restHome/form/WikiCityRestHomeFormsTabsComponent.vue'
  )
const wikiCityRestHomeInformation = () =>
  import(
    '@/views/wiki/communes/form/tabs/restHome/form/tabs/information/WikiCityRestHomeInformation.vue'
  )
const WikiCityRestHomeIndexPage = () =>
  import(
    '@/views/wiki/communes/form/tabs/restHome/index/WikiCityRestHomeIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue')
const wikiPlaceTranslationTab = () =>
  import(
    '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )
const wikiPlaceContactTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue')

export const wikiCityRestHomeRoutes = [
  {
    path: 'rest-home',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_rest_home_page_route',
        component: WikiCityRestHomeIndexPage,
        props: true,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':rest_home_id',
        name: 'wiki_city_edit_rest_home_page_route',
        component: wikiCityRestHomeFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_rest_home_information_page_route',
            component: wikiCityRestHomeInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_rest_home_translation_page_route',
            component: wikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_rest_home_contact_page_route',
            component: wikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_edit_rest_home_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_rest_home_page_route',
        props: true,
        component: wikiCityRestHomeFormsTabsComponent,
        children: [
          {
            path: '',
            name: 'wiki_city_create_rest_home_information_page_route',
            component: wikiCityRestHomeInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_create_rest_home_translation_page_route',
            component: wikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_create_rest_home_contact_page_route',
            component: wikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_create_rest_home_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
