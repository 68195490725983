<template>
  <table
    v-if="actions && columns && data"
    class="z-10 min-w-full border-separate"
    style="border-spacing: 0"
  >
    <thead class="bg-gray-50">
      <tr>
        <BaseThComponent
          v-for="(column, index) in columns"
          :key="column"
          :column="column"
          :current-sort="currentSort"
          :index="index"
          @sort-column="handleSortColumn"
        />
        <th
          v-if="Array.isArray(actions)"
          class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-3 text-left text-sm font-bold text-gray-900"
        >
          <FontAwesomeIcon
            v-if="actionCol"
            :icon="['fal', 'ellipsis-vertical']"
          />
        </th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr
        v-for="row in data"
        :key="row"
        class=""
        :draggable="rowDraggable"
        @dragstart="startDrag($event, row)"
        @drop="onDrop($event)"
        @dragover.prevent
        @dragenter.prevent
      >
        <template v-for="(column, index) in columns" :key="index">
          <slot :column="column" :data="row" :index="index" name="table-cell">
            <RowElementComponent
              v-if="!column.hidden"
              :index="index"
              :row="row"
              :column="column"
              :actions="actions"
              :ordered-row="orderedRow"
              :action-col="actionCol"
            />
          </slot>
        </template>
        <td
          v-if="Array.isArray(actions)"
          class="hidden whitespace-nowrap border-b border-gray-200 pr-2 text-sm sm:table-cell"
        >
          <div class="flex flex-nowrap justify-end gap-1">
            <div
              v-for="action in actions"
              :key="action"
              class="flex flex-nowrap justify-end gap-1"
            >
              <BaseButton
                :class-name="`${action?.bgColor} ${action?.fgColor}`"
                :icon="action?.icon"
                :icon-type="action?.iconType"
                :disabled="action?.gate"
                size="small"
                @click="() => action?.clickFunction(row)"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BaseButtonEXTRA from '@c/BaseButtonEXTRA'
import BaseThComponent from './BaseTableComponent/BaseThComponent'
import RowElementComponent from '@c/RowElementComponent'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'BaseTableComponent',
  components: {
    BaseButton,
    FontAwesomeIcon,
    BaseButtonEXTRA,
    RowElementComponent,
    BaseThComponent
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false,
      default: null
    },
    rowDraggable: {
      type: Boolean,
      required: false,
      default: false
    },
    categoryArray: {
      type: Array,
      required: false,
      default: null
    },

    actionCol: {
      type: Boolean,
      required: false,
      default: false
    },
    sortDirection: {
      type: String,
      required: true,
      default: null
    },
    sortColumn: {
      type: [Object, Array],
      required: false,
      default: () => ({ tes: 'los' })
    },
    sortType: {
      type: String,
      required: false,
      default: null
    },
    currentSort: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [],
  data() {
    return {
      draggedElement: {}
    }
  },
  computed: {
    localSortDirection: {
      get() {
        return this.sortDirection
      },
      set(value) {
        this.$emit('update:sortDirection', value)
      }
    },
    localSortColumn: {
      get() {
        return this.sortColumn
      },
      set(value) {
        // Emit an event to update the sortColumn prop
        this.$emit('update:sortColumn', value)
      }
    },
    localSortType: {
      get() {
        return this.sortType
      },
      set(value) {
        this.$emit('update:sortType', value)
      }
    },
    localCurrentSort: {
      get() {
        return this.currentSort
      },
      set(value) {
        this.$emit('update:currentSort', value)
      }
    },
    localCategoriesArray: {
      get() {
        return this.categoryArray
      },
      set(value) {
        this.$emit('update:categoryArray', value)
      }
    }
  },
  methods: {
    canDeleteItem(action) {
      return (
        (this.$can('manage_content') && action?.icon === 'edit') ||
        (this.$can('delete_content') && action?.icon === 'trash')
      )
    },
    getActionClasses(action, rowData) {
      const classes = []

      const bgColor = this.getClassesByValue(action.bgColor, rowData)

      if (bgColor) {
        classes.push(bgColor)
      }

      const fgColor = this.getClassesByValue(action.fgColor, rowData)

      if (fgColor) {
        classes.push(fgColor)
      }

      return classes.join(' ')
    },
    getClassesByValue(value, rowData) {
      if (typeof value === 'function') {
        return this.getClassesByValue(value(rowData))
      } else if (typeof value === 'string') {
        return value
      }
    },
    handleSortColumn({ column, direction, type }) {
      this.localCurrentSort = column
      this.localSortColumn = column
      this.localSortDirection = direction
      this.localSortType = type
    },
    startDrag(evt, element) {
      this.draggedElement = evt.target
    },
    onDrop(evt) {
      var targetElement = evt.toElement

      while (targetElement && targetElement.tagName !== 'TR') {
        targetElement = targetElement.parentElement
      }
      if (targetElement && targetElement.tagName === 'TR') {
        targetElement.insertAdjacentElement('afterend', this.draggedElement)
      }

      const data = { ...this.localCategoriesArray }
      const map = {}

      Object.values(data).forEach((obj) => {
        // TODO: adapt to local
        map[Object.values(data).indexOf(obj)] = obj
      })
      const tableNames = Array.from(
        document.querySelectorAll('tbody tr td:first-child a'),
        (a) => a.textContent.trim()
      )
      Object.values(map).forEach((item) => {
        const index = tableNames.indexOf(item.name_index)
        if (index !== -1) {
          item.position = index
        }
      })
      this.localCategoriesArray = map
      this.$emit('update:displayUpdateButton')
    }
  }
}
</script>
