<template>
  <transition
    enter-active-class="duration-500 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
  >
    <div
      v-if="all_is_loaded"
      class="bg-gradient-to-b from-primary to-primary-700 dark:from-primary-900 dark:to-primary-700"
    >
      <TheMobileMenu />
      <div class="side-nav-gradient flex h-screen overflow-hidden">
        <!-- BEGIN: Side Menu -->
        <div
          class="hidden flex-col md:flex print:hidden @container/menu transition-width duration-300 ease-in-out"
          :class="{
            'w-24 lg:w-64': !collapsedMenu,
            'w-24': collapsedMenu
          }"
        >
          <div class="shrink-0 px-5 py-3" style="max-width: 250px">
            <a href="" class="flex items-center justify-center gap-2">
              <img
                :src="brand_logo"
                class="inline-block @3xs/menu:w-1/3"
                alt="logo-fm"
              />
              <img
                v-if="!collapsedMenu"
                :src="brand_text"
                class="hidden @3xs/menu:inline-block @3xs/menu:w-2/3"
                alt="logo-fm"
              />
            </a>
          </div>
          <nav class="px-5 pb-2 overflow-x-hidden flex-1 overflow-y-auto">
            <MainMenu :key="keyForRefreshMenu" />
          </nav>
          <div class="shrink-0 cursor-pointer py-1 pl-5 hidden @3xs/menu:flex">
            <FontAwesomeIcon
              :icon="menuCollapseIcon"
              size="lg"
              class="text-white"
              @click="toggleCollapsed"
            />
          </div>
          <div class="shrink-0">
            <ReleaseNotificationComponent />
          </div>
          <div
            class="flex shrink-0 border-t p-4 justify-center @3xs/menu:justify-start"
            style="max-width: 250px"
          >
            <InfoUserModalComponent />
          </div>
        </div>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content flex flex-col">
          <TheTopBar @ask-for-refresh="incrementKeyForRefresh" />
          <div class="min-h-0 flex-1 p-6">
            <RouterView :key="keyForRefresh" />
          </div>
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </transition>
  <transition
    leave-active-class="duration-500 ease-out"
    leave-from-class="transform opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div
      v-if="!all_is_loaded"
      id="loading-image"
      class="inset-0 flex h-screen w-screen flex-col items-center justify-center gap-5"
    >
      <img class="-intro-y" :src="brand_logo_full" width="250" />
      <div class="intro-y rounded-xl bg-white px-5 py-1.5 font-bold">
        {{ loadingMessage }}
      </div>
    </div>
  </transition>
  <FormInputConfigurator />
  <NotificationsContainer />
  <UnreadedVersionNoteModal
    :open="modalVersionNote"
    :versions="getInitialMetadata?.models?.unreaded_version_notes"
    @close-modal-version="closeUnreadedVersionNoteModal"
  />
</template>

<script>
import { onMounted } from 'vue'

import fm_logo from '../../../assets/images/funeral-manager-logo-only.svg'
import fm_text from '../../../assets/images/funeral-manager-text-only.svg'
import fm_logo_full from '../../../assets/images/funeral-manager-full.svg'
import funero_logo from '../../../assets/images/funero-logo-only.svg'
import funero_text from '../../../assets/images/funero-text-only.svg'
import funero_logo_full from '../../../assets/images/funero-logo-full.svg'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import UnreadedVersionNoteModal from '@ui/modal/versionNote/UnreadedVersionNoteModal.vue'
import NotificationsContainer from '@/views/layouts/main-layout/ToastsContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormInputConfigurator from '@c/FormInputConfigurator.vue'
import i18n from '@/i18n'
import MainMenu from '@/views/layouts/main-layout/MainMenu.vue'
import TheMobileMenu from '@/views/layouts/mobile-menu/TheMobileMenu.vue'
import InfoUserModalComponent from '@/views/layouts/top-bar/InfoUserModalComponent.vue'
import TheTopBar from '@/views/layouts/top-bar/TheTopBar.vue'
import ReleaseNotificationComponent from '@/views/settings/versionNote/component/ReleaseNotificationComponent.vue'

export default {
  components: {
    ReleaseNotificationComponent,
    UnreadedVersionNoteModal,
    NotificationsContainer,
    FormInputConfigurator,
    TheTopBar,
    InfoUserModalComponent,
    FontAwesomeIcon,
    MainMenu,
    TheMobileMenu
  },
  data() {
    return {
      loadingMessage: '',
      keyForRefresh: 0,
      keyForRefreshMenu: 0
    }
  },
  setup() {
    onMounted(() => {
      document.body.classList.remove('error-page')
      document.body.classList.remove('login')
      document.body.classList.add('main')
      document.querySelectorAll('#app > div').forEach((element) => {
        element.classList.remove('content')
      })
    })
    return {}
  },
  computed: {
    ...mapGetters({
      collapsedMenu: 'sideMenu/collapsedMenu',
      getMetadataLoaded: 'metadata/getMetadataLoaded',
      getInitialMetadata: 'metadata/getInitialMetadata',
      modalVersionNote: 'metadata/getOpenModalVersionNote',
      functionalities: 'metadata/getFunctionalities',
      authUser: 'auth/getUser'
    }),
    all_is_loaded() {
      return this.authUser !== null && this.getMetadataLoaded
    },
    brand_logo() {
      if (this.$brand === 'funero') {
        return funero_logo
      }
      return fm_logo
    },
    brand_text() {
      if (this.$brand === 'funero') {
        return funero_text
      }
      return fm_text
    },
    brand_logo_full() {
      if (this.$brand === 'funero') {
        return funero_logo_full
      }
      return fm_logo_full
    },
    menuCollapseIcon() {
      return [
        'fal',
        this.collapsedMenu ? 'chevron-double-right' : 'chevron-double-left'
      ]
    }
  },
  mounted() {
    this.loadUser()
    setTimeout(async () => {
      this.processToggleModalVersionNoteMutation(
        this.getInitialMetadata?.models?.unreaded_version_notes?.length > 0
      )
    }, 2000)
    this.emitter.on('reload-menu', () => {
      this.keyForRefreshMenu += 1
    })
    if (navigator.platform.indexOf('Win') !== -1) {
      document.body.classList.add('windows')
    }
  },
  methods: {
    ...mapActions('sideMenu', ['toggleCollapsed']),
    ...mapActions({
      refreshAuth: 'auth/refreshAuth',
      refreshNotificationCenter: 'notification/fetchNotifications',
      fetchMetadata: 'metadata/fetchAllMetadata',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels',
      processGetAuthUserAction: 'auth/getAuthUser'
    }),
    ...mapMutations({
      processToggleModalVersionNoteMutation: 'metadata/toggleModalVersionNote',
      setPermissions: 'auth/setPermissions',
      setAuthUser: 'auth/setAuthUser'
    }),
    loadUser() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ name: 'login' })
      }
      this.loadingMessage = this.$t('global.loading', 'loading')
      this.processGetAuthUserAction()
        .then((response) => {
          this.setAuthUser(response.user)
          this.loadPermission()
        })
        .catch((_reason) => {
          this.$router.push({ name: 'login' })
        })
    },
    loadPermission() {
      this.loadingMessage = this.$t('global.loading_permissions', 'loading')
      this.setPermissions()
      if (this.authUser.language !== this.$i18n.locale) {
        this.loadUserLocale()
      } else {
        this.loadMetadata()
      }
    },
    loadUserLocale() {
      this.loadingMessage = this.$t('global.loading_locale', 'loading')
      i18n.setLocale(this.authUser.language).then((_) => {
        this.loadMetadata()
      })
    },
    loadMetadata() {
      this.loadingMessage = this.$t('global.loading_data', 'loading')
      this.fetchMetadata()
    },
    closeUnreadedVersionNoteModal() {
      this.processToggleModalVersionNoteMutation(false)
    },
    incrementKeyForRefresh() {
      this.keyForRefresh += 1
    }
  }
}
</script>
