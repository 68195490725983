import apiClient from '@u/apiClient'

export default {
  // region Fetch
  fetchPaymentModeList: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get('payment-mode')
        .then((response) => {
          commit('setPaymentModeList', response.data.payment_modes)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchPaymentModeById: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`payment-mode/${payload.id}`)
        .then((response) => {
          commit('setPaymentMode', response.data.payment_mode)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewPaymentMode({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('model/new?model=paymentMode')
        .then((response) => {
          commit('setPaymentMode', response.data.paymentMode)
          commit('setPaymentModeMetadata', response.data.metadata)
          // resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchPaymentModeActivityByPaymentModeID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`payment-mode/${payload.payment_mode_id}/activities`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region store
  storePaymentMode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .post('payment-mode', payload.payment_mode)
        .then((response) => {
          commit('setPaymentMode', response.data.payment_mode)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updatePaymentMode: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`payment-mode/${payload.id}`, payload.payment_mode)
        .then((response) => {
          commit('setPaymentMode', response.data.payment_mode)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region delete
  deletePaymentModeById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`payment-mode/${payload.id}`)
        .then((response) => {
          commit('setPaymentModeList', response.data.payment_modes)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  undoDeletePaymentModeById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`payment-mode/${payload.id}/undo-delete`)
        .then((response) => {
          commit('setPaymentModeList', response.data.payment_modes)
          commit('setPaymentModeMetadata', response.data.metadata)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
