<template>
  <Menu as="div">
    <Float
      :auto-placement="{
        autoAlignment: true,
        allowedPlacements: [
          'bottom-end',
          'bottom-start',
          'top-end',
          'top-start'
        ]
      }"
      :offset="3"
      transition-name="drop-down-menu"
    >
      <MenuButton
        class="btn btn flex cursor-pointer flex-row-reverse gap-2 bg-white px-2.5 py-0.5 text-gray-900 active:scale-95"
        :class="buttonClass"
        :disabled="disabled"
      >
        <div v-if="isButton" class="flex flex-row items-center gap-2">
          <FontAwesomeIcon
            v-if="showIconBefore"
            :icon="['fal', icon ?? 'plus']"
            class="leading-6 text-gray-900 dark:border-white dark:bg-slate-500 dark:text-gray-300"
          />
          <span v-if="title" class="leading-6 capitalize-first">
            {{ title }}
          </span>
          <FontAwesomeIcon
            v-if="isLoading"
            :icon="['fal', icon ?? 'spinner-third']"
            class="animate-spin leading-6 text-gray-900 dark:border-white dark:bg-slate-500 dark:text-gray-300"
          />
          <FontAwesomeIcon
            v-if="showIconAfter"
            :icon="['fal', icon ?? 'ellipsis-v']"
            class="leading-6 text-gray-900 dark:border-white dark:bg-slate-500 dark:text-gray-300"
          />
        </div>
        <div v-else>
          <FontAwesomeIcon class="leading-6" :icon="['fal', 'ellipsis-v']" />
        </div>
      </MenuButton>
      <MenuItems
        class="rounded-md border bg-white shadow-lg dark:border-white dark:bg-slate-700 dark:text-gray-300"
        :class="[menuItemsClass]"
      >
        <div class="flex flex-col divide-y">
          <div
            v-for="(firstLevel, i) in normalizedOptions"
            :key="i"
            class="flex flex-col px-1 py-1"
          >
            <div v-for="(option, j) in firstLevel" :key="j">
              <MenuItem
                v-if="option.gate && option.import"
                :class="[menuItemClass]"
              >
                <div
                  class="flex w-full flex-row items-center rounded-md px-2"
                  :class="[
                    option.bg_color ??
                      'bg-white hover:bg-gray-50 dark:bg-slate-700',
                    option.txt_color ?? 'txt-slate-900',
                    option.disabled ?? 'bg-gray-200'
                  ]"
                >
                  <input
                    id="fileUpload"
                    ref="fileInput"
                    type="file"
                    hidden
                    @change="onFilePicked"
                  />
                  <button @click.stop="chooseFiles()">
                    <FontAwesomeIcon
                      :icon="['fal', option.icon]"
                      class="mr-2"
                    />
                    <p class="inline-block capitalize-first">
                      {{ option.label }}
                    </p>
                  </button>
                </div>
              </MenuItem>
              <MenuItem
                v-else-if="option.gate"
                :class="[
                  menuItemClass,
                  option.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                ]"
                @click.stop="() => makeAction(option)"
              >
                <div
                  class="flex w-full flex-row items-center rounded-md px-2 dark:border-white dark:bg-slate-700 dark:text-gray-300"
                  :class="[
                    option.txt_color ?? 'text-slate-900',
                    option.disabled
                      ? 'bg-gray-200'
                      : (option.bg_color ??
                        'bg-white hover:bg-gray-50 dark:bg-slate-700')
                  ]"
                >
                  <FontAwesomeIcon
                    :icon="[option.icon_type ?? 'fal', option.icon]"
                  />
                  <span class="ml-2 capitalize-first">
                    {{ option.label }}
                  </span>
                </div>
              </MenuItem>
            </div>
          </div>
        </div>
      </MenuItems>
    </Float>
    <!--#endregion -->
  </Menu>
</template>

<script>
import { Float } from '@headlessui-float/vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseDropDownMenu',
  components: { FontAwesomeIcon, Menu, MenuButton, MenuItems, MenuItem, Float },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    widthSize: {
      type: String,
      required: false,
      default: null
    },
    isButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showIconBefore: {
      type: Boolean,
      required: false,
      default: false
    },
    showIconAfter: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    entry: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  computed: {
    buttonClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-0 gap-1 text-xs'
        case 'small':
          return 'px-2.5 py-0.5 gap-2'
        case 'large':
          return 'px-4 py-3 gap-3'
        case 'extra-large':
          return 'px-5 py-4 gap-3 text-base'
        case 'medium':
        default:
          return 'px-3 py-1.5 gap-3'
      }
    },
    menuItemClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-1 text-xs'
        case 'small':
          return 'px-3 py-1.5 text-sm'
        case 'large':
          return 'px-5 py-3 text-sm'
        case 'medium':
        default:
          return 'px-4 py-2 text-sm'
      }
    },
    menuItemsClass() {
      if (this.widthSize) {
        switch (this.widthSize) {
          case 'extra-small':
            return 'w-52'
          case 'small':
            return 'w-56'
          case 'large':
            return 'w-64'
          case 'extra-large':
            return 'w-72'
          case 'medium':
          default:
            return 'w-60'
        }
      }
      switch (this.size) {
        case 'extra-small':
          return 'w-52'
        case 'small':
          return 'w-56'
        case 'large':
          return 'w-64'
        case 'medium':
        default:
          return 'w-60'
      }
    },
    normalizedOptions() {
      if (Array.isArray(this.options)) {
        if (Array.isArray(this.options[0])) {
          // je remove les array empty
          return this.options.filter((element) => element.length !== 0)
        }
        return [this.options]
      }
      return []
    }
  },
  methods: {
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
    onFilePicked(event) {
      this.$emit('file', event.target.files[0])
      this.$refs.fileInput.value = ''
    },
    makeAction(option) {
      if (!option.disabled && typeof option.action === 'function') {
        option.action(this.entry)
      }
    }
  }
}
</script>

<style>
.drop-down-menu-enter-active {
  transition: all 0.3s ease-out;
}

.drop-down-menu-leave-active {
  transition: all 0.3s ease-in;
}

.drop-down-menu-enter-from,
.drop-down-menu-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}
</style>
