<template>
  <div
    :data-tint="color"
    :class="`inline-flex items-center space-x-2 px-3 py-1 m-1 rounded-md text-contrast-primary border border-primary bg-primary-100`"
  >
    <span class="w-2.5 h-2.5 rounded-full bg-primary"></span>
    <span :class="`font-bold text-gray-600`">
      {{ badgeText }}
    </span>
    <FontAwesomeIcon
      :icon="['fas', 'times']"
      class="text-red-600 cursor-pointer"
      @click="$emit('remove-badge', { element: badgeElement, list: list })"
    />
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BadgeItemComponent',
  components: { FontAwesomeIcon },
  props: {
    badgeElement: {
      type: Object,
      required: true
    },
    list: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    badgeText: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['remove-badge']
}
</script>
