import apiClient from '@u/apiClient'
import router from '@/router'
import Bugsnag from '@bugsnag/js'

export default {
  // region login
  login({ commit }, loginData) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`login`, loginData.auth_form, {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json'
          }
        })
        .then((response) => {
          commit('setAuth', {
            auth: response.data.auth,
            expiration: response.data.expiration,
            token: response.data.token
          })
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('expiration', response.data.expiration)
          Bugsnag.leaveBreadcrumb('connection', response.data.auth.full_name)
          Bugsnag.setUser(
            response.data.auth.id,
            response.data.auth.email,
            response.data.auth.full_name
          )
          resolve(response.data)
        })
        .catch((reason) => reject(reason))
    })
  },
  // endregion

  // region logout
  async logout({ commit, dispatch }, payload) {
    await commit('setAuth', { auth: {}, expiration: null, errors: [] })
    dispatch('metadata/resetState', payload, { root: true })
    localStorage.removeItem('token')
    localStorage.removeItem('expiration')
    router.push({ name: 'login' })
  },
  // endregion

  getAuthUser({}) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('/auth/user')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateAuthUser: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/auth/user`, payload)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region refreshAuth
  async refreshAuth({ commit, state }, payload) {
    apiClient.get('sanctum/csrf-cookie').then(async (response) => {
      await apiClient
        .get('/refreshAuth')
        .then(async (response) => {
          await commit('setAuth', {
            auth: response.data.auth,
            expiration: response.data.expiration
          })
          await commit('setPermissions', response.data.auth)
          await commit('settings/setSystemSettings', response.data.settings, {
            root: true
          })
        })
        .catch((error) => {
          commit('auth/setErrors', {
            errors: error.response
          })
        })
    })
  },
  // endregion

  // region init pusher
  async initPusher({ commit, state }, payload) {
    apiClient
      .get('/getPusherKey')
      .then(async (response) => {
        payload.successCallback(response.data.key)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  // endregion

  setAuthUser: ({ commit }, payload) => {
    commit('setAuthUser', payload.user)
  },
  updateUserReleaseNoteVersion({ commit }, version) {
    commit('updateReleaseNoteVersion', version)
  }
}
