const WorkInProgressComponent = () =>
  import('@/views/wip/WorkInProgressComponent')
const TheContactIndexPage = () =>
  import('@/views/contact/TheContactIndexPage.vue')

export const contactsRootPages = {
  path: 'contacts',
  name: 'contacts_route',
  component: WorkInProgressComponent
}

export const contactsSubPages = [
  {
    path: 'contacts/address-book',
    name: 'address_book_page_route',
    component: TheContactIndexPage
  }
]
