<template>
  <template v-if="editionMode">
    <div
      class="relative flex w-full rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm focus-within:border-primary-400 focus-within:ring-0"
      :class="label ? 'mt-2' : ''"
    >
      <BaseLabelTop
        :target="'name'"
        :label="label"
        :disabled="disabled"
        :required="required"
      />
      <VDatePicker
        v-model.range="dateValue"
        :attributes="computedAttributes"
        :popover="{
          visibility: 'click'
        }"
        :view="view"
        :locale="$i18n.locale.replace('_', '-')"
        :min-date="minDate"
        :max-date="maxDate"
        :columns="2"
        :is-required="required"
      >
        <template #default="{ inputValue, inputEvents }">
          <input
            type="text"
            :value="`${(inputValue.start ?? '          ') + '  &#62;  ' + (inputValue.end ?? '          ')}`"
            class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 outline-0 focus:ring-0 sm:text-sm"
            readonly
            v-on="inputEvents.start"
          />
        </template>
      </VDatePicker>
      <button
        v-if="!required"
        type="button"
        tabindex="-1"
        class="absolute inset-y-0 right-0 mr-3 flex items-center pl-3 text-red-600"
        @click.prevent="reset"
      >
        <FontAwesomeIcon :icon="['fal', 'times']" />
      </button>
    </div>
    <template v-if="errors">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help -mt-1 text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>
    </template>
  </template>
  <template v-else>
    <BaseShowLabel
      :label="label"
      :model-value="`${(start ?? '          ') + '  &#62;  ' + (end ?? '          ')}`"
    />
  </template>
</template>

<script>
import moment from 'moment'
import { DatePicker as VDatePicker } from '@angelblanco/v-calendar'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseLabelTop from '@c/addf-package/components/BaseLabel/BaseLabelTop.vue'

export default {
  name: 'BaseShowEditDateRangePicker',
  components: { BaseLabelTop, FontAwesomeIcon, BaseShowLabel, VDatePicker },
  props: {
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    view: {
      type: String,
      required: false,
      default: 'monthly'
    },
    disableDatePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        ;[]
      }
    },
    todayDefault: {
      type: Boolean,
      required: false,
      default: true
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    extraDots: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['update:start', 'update:end'],
  data() {
    return {}
  },
  computed: {
    computedAttributes() {
      let attributes = [
        {
          key: 'today',
          highlight: {
            fillMode: 'light'
          },
          dates: new Date()
        }
      ]
      if (Array.isArray(this.extraDots) && this.extraDots.length) {
        attributes = [
          ...attributes,
          ...this.extraDots.map((dot) => {
            return {
              key: dot.key,
              dates: moment(dot.date).toDate(),
              dot: {
                color: dot.color
              }
            }
          })
        ]
      }
      return attributes
    },
    dateValue: {
      get() {
        return {
          start: moment(this.start).toDate(),
          end: moment(this.end).toDate()
        }
      },
      set(value) {
        this.$emit('update:start', moment(value.start).format('YYYY-MM-DD'))
        this.$emit('update:end', moment(value.end).format('YYYY-MM-DD'))
      }
    }
  }
}
</script>
