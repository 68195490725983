<template>
  <div
    id="pdf-container"
    class="relative flex min-h-0 flex-1 flex-col bg-gray-700 px-3"
  >
    <div
      v-if="toolbar"
      id="pdf-container__toolbar"
      class="absolute inset-x-3 top-3 z-20 opacity-100"
    >
      <div class="relative h-10 rounded-lg border bg-white p-1 shadow-2xl">
        <div class="absolute inset-y-1 left-1 flex flex-row gap-1">
          <BaseButton
            v-if="backRoute?.name"
            :title="$t('button.back')"
            icon="chevron-left"
            size="small"
            inverse-icon-order
            color="primary"
            @click="$router.push(backRoute)"
          />
          <button
            v-if="$slots['option-pane'] && !reversePanelPosition"
            class="flex items-center rounded-md bg-secondary px-2.5 font-bold text-contrast-secondary"
            @click="optionPanelHided = !optionPanelHided"
          >
            <FontAwesomeIcon :icon="['fal', 'sidebar']" size="lg" />
          </button>
          <div
            v-if="pageCount > 1"
            class="flex w-fit flex-row items-center rounded border"
          >
            <button
              class="px-2"
              :disabled="disableBtnPrevious"
              @click="goPrevious"
            >
              <FontAwesomeIcon :icon="['fal', 'chevron-left']" />
            </button>
            <div
              class="border-x border-x-slate-200 px-2 font-semibold leading-6"
            >
              {{ page }} / {{ pageCount }}
            </div>
            <button class="px-2" :disabled="disableBtnNext" @click="goNext">
              <FontAwesomeIcon :icon="['fal', 'chevron-right']" />
            </button>
          </div>
        </div>
        <div class="flex flex-1 items-center justify-center">
          <h2 class="text-lg font-bold">{{ title }}</h2>
        </div>
        <div class="absolute inset-y-1 right-1 flex flex-row justify-end gap-1">
          <BaseButton
            v-if="blob && !hideActionDropdown"
            :title="$t('button.print')"
            icon="print"
            color="primary"
            size="small"
            @click="print"
          />
          <BaseDropDownMenu
            v-if="blob && !hideActionDropdown"
            :options="dropDownMenuOption"
            size="small"
            :title="$t('global.action')"
          />
          <BaseButton
            v-if="$slots['option-pane'] && reversePanelPosition"
            theme-color="bg-secondary text-contrast-secondary"
            icon="sidebar-flip"
            icon-type="fal"
            size="small"
            inverse-icon-order
            @click="optionPanelHided = !optionPanelHided"
          />
          <BaseButton
            v-if="savePreview"
            class="mr-4"
            :title="$t('button.save_preview')"
            color="secondary"
            size="small"
            @click="saveBinaryToBackend"
          />
        </div>
      </div>
    </div>
    <div
      class="relative flex min-h-0 flex-1 overflow-x-hidden"
      :class="[
        reversePanelPosition ? 'flex-row-reverse' : 'flex-row',
        optionPanelHided ? 'gap-0' : 'gap-5'
      ]"
    >
      <div
        v-if="$slots['option-pane']"
        class="relative mb-3 mt-16 flex shrink-0 transition-width duration-500"
        :class="[optionPanelHided ? 'w-0' : 'w-96']"
      >
        <div
          class="absolute inset-y-0 w-96 flex-1 overflow-hidden rounded-md bg-white p-2 transition-all"
          :class="[reversePanelPosition ? 'left-0' : 'right-0']"
        >
          <slot
            name="option-pane"
            :toggle-side-panel="toggleSidePanel"
            :drop-down-menu-option="dropDownMenuOption"
            :current-page="page"
          />
        </div>
      </div>
      <div
        id="container-vue-pdf-embed"
        class="scrollbar-hidden flex-1 overflow-y-auto @container/pdf"
        :class="toolbar ? 'pt-16' : 'pt-4'"
      >
        <div class="mx-auto w-full @3xl/pdf:w-3/4">
          <div class="h-full">
            <Transition
              mode="out-in"
              enter-active-class="duration-300 ease-out"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-200 ease-in"
              leave-from-class="opacity-100 "
              leave-to-class="opacity-0"
            >
              <div
                v-if="documentError"
                class="flex aspect-A4 w-full flex-col bg-slate-200 p-10 pt-20"
              >
                <div class="mx-auto mt-10 flex flex-col gap-4">
                  <FontAwesomeLayers class="fa-4x mx-auto">
                    <FontAwesomeIcon
                      :icon="['fal', 'file']"
                      class="text-slate-500"
                    />
                    <FontAwesomeIcon
                      :icon="['fas', 'circle-xmark']"
                      transform="shrink-8 down-5 right-3"
                      class="text-red-200"
                    />
                  </FontAwesomeLayers>
                  <p class="text-center font-bold">
                    {{ $t('alert.error_generating_pdf') }}
                  </p>
                </div>
              </div>
              <div
                v-else-if="!blob"
                class="flex aspect-A4 w-full flex-col bg-white p-10 pt-20"
              >
                <div class="flex animate-pulse justify-center">
                  <div
                    class="mb-10 h-4 w-72 rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                </div>
                <div role="status" class="max-w-sm animate-pulse">
                  <div
                    class="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <div
                    class="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <div
                    class="mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <div
                    class="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <div
                    class="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <div
                    class="h-2 max-w-[360px] rounded-full bg-gray-200 dark:bg-gray-700"
                  ></div>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <VuePdfEmbed
                v-else
                ref="ref-pdf-view"
                :source="pdfSrc"
                :page="page"
                :height="height"
                @rendered="handleDocumentRender"
                @loaded="handleDocumentLoaded"
                @loading-failed="handleLoadingFailed"
                @rendering-failed="handleRenderingFailed"
              />
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SendRecordInfoDialogModal
    v-if="openSendRecordDialog && sendRecord"
    :open="openSendRecordDialog"
    :send-record="sendRecord"
    @close="closeSendRecordDialog"
    @add-send-record-confirm="handleAddingSendRecord"
  />
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
import { debounce } from '@u/debounce'
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import apiClient from '@u/apiClient'
import apiClientDocument from '@u/apiClientDocument'
import BaseDropDownMenu from '@c/addf-package/components/dropdown/BaseDropDownMenu.vue'
import { mapActions, mapGetters } from 'vuex'
import SendRecordInfoDialogModal from '@/views/invoicing/show/tabs/information/components/rightPanel/component/SendRecordInfoDialogModal.vue'

export default {
  name: 'PdfViewer',
  components: {
    FontAwesomeLayers,
    SendRecordInfoDialogModal,
    BaseDropDownMenu,
    BaseButton,
    FontAwesomeIcon,
    VuePdfEmbed
  },
  props: {
    url: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      required: false,
      default: () => {}
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    document: {
      type: Object,
      required: false,
      default: null
    },
    infoModel: {
      type: Object,
      required: false,
      default: () => {}
    },
    backRoute: {
      type: Object,
      required: false,
      default: () => {}
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: true
    },
    savePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    reversePanelPosition: {
      type: Boolean,
      required: false,
      default: false
    },
    uploadToEnvelope: {
      type: Boolean,
      required: false,
      default: false
    },
    uploadToEnvelopeAction: {
      type: Function,
      required: false,
      default: undefined
    },
    hideActionDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    attachments: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: [
    'update:page',
    'click-coordinate',
    'save-preview',
    'refresh-attachments:attachments',
    'refresh-attachments'
  ],
  data() {
    return {
      blob: null,
      pageCount: null,
      page: 1,
      containerHeight: null,
      fit: false,
      optionPanelHided: false,
      resizerObserver: null,
      firstRendered: false,
      currentWidth: 0,
      documentElement: null,
      fileNames: [],
      openSendRecordDialog: false,
      sendRecord: null,
      documentError: false
    }
  },
  computed: {
    ...mapGetters({
      invoice: 'invoicing/getInvoice',
      auth: 'auth/getAuth'
    }),
    disableBtnPrevious() {
      return this.page === 1
    },
    disableBtnNext() {
      return this.page >= this.pageCount
    },
    height() {
      return this.fit ? this.containerHeight : null
    },
    pdfSrc() {
      return this.blob ? URL.createObjectURL(this.blob) : ''
    },
    dropDownMenuOption() {
      return [
        [
          {
            label: this.$t('button.add_to_envelope'),
            icon: 'file-signature',
            action: () => this.addToEnvelope(),
            gate: this.uploadToEnvelope
          },
          {
            label: this.$t('button.send_by_email'),
            icon: 'paper-plane',
            action: () => this.sendByMail(),
            gate: true
          },
          {
            label: this.$t('button.print'),
            icon: 'print',
            action: () => this.print(),
            gate: true
          },
          {
            label: this.$t('global.clip_document'),
            icon: 'paperclip',
            action: () => this.saveInAttachments(),
            gate: this.infoModel?.dossier_id
          },
          {
            label: this.$t('button.save'),
            icon: 'download',
            action: () => this.download(),
            gate: true
          }
        ]
      ]
    }
  },
  mounted() {
    this.resizerObserver = new ResizeObserver(this.resizeEventHandler)
    this.resizerObserver.observe(
      document.getElementById('container-vue-pdf-embed'),
      { box: 'border-box' }
    )
    this.loadDocument()
  },
  beforeUnmount() {
    this.resizerObserver.disconnect()
  },
  created() {
    this.debouncedRender = debounce((entries) => {
      if (entries[0].borderBoxSize[0].inlineSize !== this.currentWidth) {
        if (this.currentWidth !== 0) {
          this.calcHeight()
        }
        this.currentWidth = entries[0].borderBoxSize[0].inlineSize
      }
    }, 600)
  },
  methods: {
    ...mapActions({
      processAddModelActivityAction: 'modelActivity/addModelActivity',
      processAddPdfToEnvelopeAction: 'electronicSignature/addPdfToEnvelope'
    }),
    goPrevious() {
      this.page -= 1

      this.$emit('update:page', this.page)
    },
    goNext() {
      this.page += 1

      this.$emit('update:page', this.page)
    },
    loadDocument() {
      this.documentError = false
      apiClientDocument
        .post(this.url, this.params, { responseType: 'blob' })
        .then(async (response) => {
          this.blob = response.data
          this.page = 1
        })
        .catch((reason) => {
          this.documentError = true
        })
    },
    resizeEventHandler(entries) {
      this.debouncedRender(entries)
    },
    async print() {
      if (this.infoModel.type === 'invoice' && !this.$route.params.payment_id) {
        await this.addActivityToInfoModel('impression', 'activity_print_doc')
      }
      this.$refs['ref-pdf-view']?.print(300, `${this.title}.pdf`, true)
    },
    addActivityToInfoModel(
      activityType,
      details,
      subject_type = null,
      subject_id = null
    ) {
      this.processAddModelActivityAction({
        activity: {
          activity_type: activityType,
          subject_id: subject_id,
          subject_type: subject_type,
          model_id: this.infoModel.id,
          model_type: 'App\\Models\\Invoice',
          user_id: this.auth.id,
          details: details
        }
      }).then((response) => {})
    },
    download() {
      const link = document.createElement('a')
      link.href = this.pdfSrc
      link.download = this.title
      document.body.append(link)
      link.click()
      link.remove()
      if (this.infoModel.type === 'invoice' && !this.$route.params.payment_id) {
        this.addActivityToInfoModel('download', 'activity_save_doc')
      }
    },
    async saveInAttachments() {
      const file_name = this.title?.replaceAll('/', '_')
      if (this.attachments.includes(file_name)) {
        this.$h.toastWarning(this.$t('toasts.document_already_annexed'))
        return
      }
      const formData = new FormData()
      formData.append('file', this.blob, this.title)
      formData.append(
        'attachments[]',
        this.blob,
        this.title?.replaceAll('/', '_')
      )

      try {
        const response = await apiClient.post(
          `/dossier/${this.infoModel?.dossier_id}/add-attachments?save_doc_as_attachment=true`,
          formData
        )
        if (response.status === 200) {
          this.$h.toastSuccess(this.$t('toasts.document_attached'))
          if (this.infoModel.type === 'dossier') {
            this.$emit('refresh-attachments', response.data.attachments)
          }
          if (
            this.infoModel.type === 'invoice' &&
            !this.$route.params.payment_id
          ) {
            await this.addActivityToInfoModel(
              'annexe',
              'activity_annexe_doc',
              'App\\Models\\Dossier',
              this.infoModel?.dossier_id
            )
          }
        } else {
          this.$h.toastDanger(
            this.$t('validation.mediaLibrary.somethingWentWrong')
          )
        }
      } catch (error) {
        this.$h.toastDanger(
          this.$t('validation.mediaLibrary.somethingWentWrong')
        )
      }
    },
    sendByMail() {
      if (!this.infoModel || !this.infoModel.type || !this.infoModel.id) {
        return
      }
      if (
        this.infoModel.type === 'invoice' &&
        this.invoice &&
        this.invoice.doc_type === 'INV' &&
        !this.$route.params.payment_id
      ) {
        this.openSendRecordDialog = true
        this.sendRecord = {
          sender_id: this.auth.id,
          send_type: null,
          send_method: 'email'
        }
      } else {
        this.sendModelByEmail()
      }
    },
    toggle() {
      this.optionPanelHided = !this.optionPanelHided
    },
    reload() {
      this.loadDocument()
    },
    sendModelByEmail() {
      let formData = new FormData()
      formData.append(
        'pdf-document',
        this.blob,
        this.title?.replaceAll('/', '_')
      )
      formData.append('model_type', this.infoModel.type)
      formData.append('model_id', this.infoModel.id)
      formData.append('dossier_id', this.infoModel.dossier_id)
      formData.append('header_id', this.infoModel.header_id)
      formData.append('document_id', this.infoModel.document_id)
      if (this.infoModel.type === 'invoice' && this.sendRecord) {
        formData.append('send_record', JSON.stringify(this.sendRecord))
      }
      apiClientDocument
        .post('/notification/create-from-pdf', formData)
        .then((response) => {
          this.$router.push({
            name: 'mail_box_edit_route',
            params: {
              message_id: response.data.model_notification.id
            }
          })
        })
        .catch((reason) => {
          this.$h.toastDanger('error')
        })
    },
    closeSendRecordDialog() {
      this.openSendRecordDialog = false
      this.sendRecord = null
    },
    handleAddingSendRecord() {
      this.sendModelByEmail()
    },
    handleDocumentLoaded(arg) {
      this.pageCount = arg._pdfInfo.numPages
    },
    handleDocumentRender() {
      this.handleDocumentClick()
    },
    handleDocumentClick() {
      this.documentElement = document.querySelector('.vue-pdf-embed__page')
      this.documentElement.removeEventListener('dblclick', this.dblClickEvent)
      this.documentElement.addEventListener('dblclick', this.dblClickEvent)
    },
    dblClickEvent(e) {
      let { width, height } = e.currentTarget.getBoundingClientRect()
      let { offsetX: x, offsetY: y } = e
      let percentX = ((x / width) * 100).toFixed(2)
      let percentY = ((y / height) * 100).toFixed(2)
      this.$emit('click-coordinate', [percentX, percentY])
    },
    calcHeight() {
      this.containerHeight =
        document.getElementById('pdf-container').getBoundingClientRect()
          .height -
        (this.toolbar
          ? document
              .getElementById('pdf-container__toolbar')
              .getBoundingClientRect().height
          : 0) -
        16 -
        16 -
        12

      this.$refs['ref-pdf-view']?.render()
    },
    toggleSidePanel(value) {
      this.optionPanelHided = value
    },
    addToEnvelope() {
      if (
        this.uploadToEnvelopeAction &&
        typeof this.uploadToEnvelopeAction === 'function'
      ) {
        this.uploadToEnvelopeAction()
        return
      }
      if (!this.infoModel || !this.infoModel.type || !this.infoModel.id) {
        return
      }
      let formData = new FormData()
      formData.append(
        'pdf-document',
        this.blob,
        this.title?.replaceAll('/', '_') + '.pdf'
      )
      formData.append('model_type', this.infoModel.type)
      formData.append('model_id', this.infoModel.id)
      formData.append('dossier_id', this.infoModel.dossier_id ?? null)
      formData.append('header_id', this.infoModel.header_id)
      formData.append('advisor', this.infoModel.advisor)

      this.processAddPdfToEnvelopeAction({
        params: formData
      }).then((response) => {
        if (response.electronic_signature) {
          this.$h.toastAction(
            'info',
            this.$t('toasts.document_has_been_added_to_envelope'),
            null,
            [
              {
                title: this.$t('button.see_the_envelope'),
                action: () => {
                  // process de la restauration
                  this.$router.push({
                    name: 'electronic_signature_view_route',
                    params: {
                      electronicSignatureId: response.electronic_signature.id,
                      status: response.electronic_signature.status.replaceAll(
                        '_',
                        '-'
                      )
                    }
                  })
                }
              }
            ]
          )
        }
      })
    },
    blobToBinary() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          const binaryStr = reader.result
          resolve(binaryStr)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(this.blob) // Lire le blob en tant qu'ArrayBuffer
      })
    },
    async saveBinaryToBackend() {
      try {
        const binaryData = await this.blobToBinary()
        const formData = new FormData()
        formData.append('pdf-document', new Blob([binaryData]))

        const response = await apiClient.post(
          `/editor/${this.document.document_type}/${this.document.id}/save-preview`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.success) {
          this.$h.toastSuccess(this.$t('toasts.save_document_preview'))
        }
      } catch (error) {
        this.$h.toastDanger(
          this.$t('validation.mediaLibrary.somethingWentWrong')
        )
      }
    },
    handleLoadingFailed(e) {},
    handleRenderingFailed(e) {}
  }
}
</script>
