<template>
  <div class="top-bar -mx-4 w-full px-4 md:mx-0 md:px-6 print:hidden">
    <div class="flex w-full flex-row">
      <div class="flex flex-shrink-0 flex-row items-center gap-4">
        <QuickAdd />
        <CommandPalette />
        <ScanComponent />
      </div>
      <div class="flex flex-1 flex-row items-center justify-end gap-x-3">
        <div
          class="cursor-pointer flex-row items-center justify-center rounded-md border bg-white px-2 py-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
          @click="$router.push({ name: 'catalog_list_items_route' })"
        >
          <FontAwesomeIcon fixed-width :icon="['fal', 'books']" />
        </div>
        <div
          v-if="$can('super-admin')"
          class="flex-row items-center justify-center rounded-md border bg-white px-2 py-2 font-bold dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
        >
          <div class="sm:hidden">MOBILE</div>
          <div class="hidden sm:block md:hidden">SM</div>
          <div class="hidden md:block lg:hidden">MD</div>
          <div class="hidden lg:block xl:hidden">LG</div>
          <div class="hidden xl:block xxl:hidden">XL</div>
          <div class="hidden xxl:block">XXL</div>
        </div>
        <HelpMeModal />
        <LanguageSelector />
        <BaseButton
          v-if="emailStateHasChanged"
          :title="$t('global.not_sent_email')"
          icon="triangle-exclamation"
          size="small"
          color="info"
          text-color="red-500 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
          @click="openEmailModal"
        />
        <NotificationComponent v-if="pusherReady" />
        <AutomationSuggestion v-if="pusherReady" />
        <div class="flex">
          <BaseButton
            color="secondary"
            icon="sync"
            :tooltip="$t('button.refresh')"
            size="small"
            @click="$emit('askForRefresh')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationIconComponent from './NotificationIconComponent'
import CommandPalette from '@/views/layouts/top-bar/components/CommandPalette.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import QuickAdd from '@/views/layouts/top-bar/components/QuickAdd'
import LanguageSelector from '@/views/layouts/top-bar/components/LanguageSelector.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SupportModal from '@/views/layouts/top-bar/components/SupportModal.vue'
import NotificationComponent from '@c/Notification/Notification/NotificationComponent.vue'
import AutomationSuggestion from '@/views/automation/AutomationSuggestion.vue'
import Pusher from '@/plugins/pusher'
import ScanComponent from '@c/Scan/ScanComponent.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HelpMeModal from '@/views/layouts/top-bar/components/HelpMeModal.vue'

export default {
  components: {
    HelpMeModal,
    FontAwesomeIcon,
    ScanComponent,
    AutomationSuggestion,
    NotificationComponent,
    SupportModal,
    BaseButton,
    LanguageSelector,
    QuickAdd,
    NotificationIconComponent,
    CommandPalette
  },

  data() {
    return {
      breadcrumb: [],
      pusherReady: false,
      user_channel: null,
      general_presence_channel: null,
      funeral_home_channel: {}
    }
  },
  computed: {
    ...mapGetters({
      notification: 'notification/getNotifications',
      emailStateHasChanged: 'email/getEmailStateHasChanged',
      tenantId: 'metadata/getTenantIdSetting',
      websocket: 'metadata/getWebsocketSetting',
      user: 'auth/getUser'
    }),
    appTitle() {
      return import.meta.env.VUE_APP_APP_NAME
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notification/fetchNotifications',
      processChangeUserOnlineStatusAction: 'metadata/changeUserOnlineStatus'
    }),
    ...mapMutations({
      processSetIsEmailModalOpenMutation: 'email/setIsModalOpen'
    }),
    openEmailModal() {
      this.processSetIsEmailModalOpenMutation(true)
    },
    handleNotificationToast(data) {
      if (data.type === 'free_text') {
        this.$h.toastInfo(data.text)
      } else {
        this.$h.toast(
          data.type ?? 'info',
          this.getNotificationText(
            data.translation.key,
            data.translation.params
          ),
          null,
          data.uuid
        )
      }
    },
    getNotificationText(key, params) {
      let final_params = {}
      for (let property in params) {
        if (params[property].type && params[property].type === 'translation') {
          final_params[property] = this.$t(params[property].value)
        } else {
          final_params[property] = params[property]
        }
      }
      return this.$t(`notifications.${key}`, final_params)
    }
  },
  watch: {
    $route(to, from) {
      this.breadcrumb = to.meta.breadcrumb
    }
  },
  mounted() {
    Pusher.setupPusher(
      this.websocket.app_key,
      this.websocket.options,
      this.tenantId
    ).then(() => {
      this.user_channel = this.$pusher
        .subscribe(`private-user.${this.user.id}`)
        .bind('notification', (data) => {
          this.handleNotificationToast(data)
        })
        .bind('event', (data) => {
          console.log('user :: event', data)
        })
      this.general_presence_channel = this.$pusher
        .subscribe(`presence-online`)
        .bind('pusher:subscription_succeeded', (members) => {
          members.each((member) => {
            this.processChangeUserOnlineStatusAction({
              user_id: member.id,
              online: true
            })
          })
        })
        .bind('pusher:member_added', (member) => {
          this.processChangeUserOnlineStatusAction({
            user_id: member.id,
            online: true
          })
        })
        .bind('pusher:member_removed', (member) => {
          this.processChangeUserOnlineStatusAction({
            user_id: member.id,
            online: false
          })
        })
      Object.keys(this.user.funeral_homes_id).map((id) => {
        this.funeral_home_channel[id] = this.$pusher
          .subscribe(`private-funeral_home.${id}`)
          .bind('notification', (data) => {
            console.log('funeral_home :: notification', data)
          })
          .bind('event', (data) => {
            console.log('funeral_home :: event', data)
          })
      })
      this.pusherReady = true
    })
  },
  unmounted() {
    this.user_channel.unsubscribe()
    this.general_presence_channel.unsubscribe()
    Object.keys(this.funeral_home_channel).map((id) =>
      this.funeral_home_channel[id].unsubscribe()
    )
  }
}
</script>
