<template>
  <HotTable ref="hotRef" :settings="localSettings" :data="localItems" />
</template>

<script>
import { HotTable } from '@handsontable/vue3'
import Handsontable from 'handsontable'
import {
  registerLanguageDictionary,
  frFR,
  enUS,
  nlNL,
  deDE,
  deCH,
  itIT,
  esMX,
  ptBR
} from 'handsontable/i18n'
import numbro from 'numbro'
import trTR from 'numbro/languages/tr-TR'
import 'handsontable/styles/handsontable.min.css'
import 'handsontable/styles/ht-theme-main.min.css'

numbro.registerLanguage(trTR)

registerLanguageDictionary(frFR)
registerLanguageDictionary(enUS)
registerLanguageDictionary(nlNL)

const languages = {
  fr_BE: frFR.languageCode,
  fr_FR: frFR.languageCode,
  fr_LU: frFR.languageCode,
  fr_CH: frFR.languageCode,
  nl_BE: nlNL.languageCode,
  nl_NL: nlNL.languageCode,
  en_UK: enUS.languageCode,
  en_US: enUS.languageCode,
  de_BE: deDE.languageCode,
  de_DE: deDE.languageCode,
  de_CH: deCH.languageCode,
  it_IT: itIT.languageCode,
  it_CH: itIT.languageCode,
  sp_SP: esMX.languageCode,
  pt_PT: ptBR.languageCode
}

Handsontable.validators.registerValidator('not-empty', function (value, cb) {
  let lastRow = this.instance.countRows() - 1
  let isOk = value !== undefined && value !== null && value !== ''

  cb(this.row === lastRow || isOk)
})

export default {
  name: 'ExcelManager',
  components: { HotTable },
  props: {
    instance: {
      type: Object,
      required: false,
      default: null
    },
    sheetName: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      required: false,
      default: () => {}
    },
    columns: {
      type: Array,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: false,
      default: null
    },
    hooks: {
      type: Object,
      required: false,
      default: () => {}
    },
    licenseKey: {
      type: String,
      required: false,
      default: 'non-commercial-and-evaluation'
    }
  },
  emits: ['update:instance'],
  data() {
    return {
      buildConfig: {},
      defaultSettings: {
        language: languages[this.$i18n.locale] || frFR.languageCode,
        contextMenu: [
          //'cut',
          'copy',
          'undo',
          'redo',
          '---------',
          'row_above',
          'row_below',
          'remove_row'
          //'---------',
          //'alignment',
          //'make_read_only',
          //'clear_column'
        ],
        themeName: 'ht-theme-main',
        colHeaders: true,
        rowHeaders: true,
        minSpareRows: 1,
        startRows: 5,
        wordWrap: true,
        autoRowSize: { syncLimit: '100%' },
        height: '100%',
        autoWrapRow: true,
        autoWrapCol: true,
        manualRowResize: true,
        manualColumnResize: true,
        columnSorting: true,
        navigableHeaders: true
      }
    }
  },
  computed: {
    localSettings() {
      return Object.assign({}, this.defaultSettings, this.settings, {
        licenseKey: this.licenseKey,
        columns: this.localColumns
      })
    },
    localColumns() {
      if (this.columns && this.columns.length > 0) {
        return this.columns
      } else if (this.disableDefault) {
        return []
      }

      return [
        {
          title: 'The name',
          type: 'text',
          data: `name.${this.$i18n.locale}`
        },
        {
          title: 'First name',
          type: 'text',
          data: 'firstName'
        },
        {
          title: 'Last name',
          type: 'text',
          data: 'lastName'
        },
        {
          title: 'Birthday',
          type: 'date',
          data: 'birthday',
          dateFormat: 'DD/MM/YYYY',
          correctFormat: true
        },
        {
          title: 'Age',
          data: 'age',
          type: 'numeric'
        },
        {
          title: 'Salary',
          data: 'salary',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'fr-FR' // this is the default culture, set up for USD
          }
        },
        {
          title: 'Civility',
          data: 'civility',
          type: 'dropdown',
          source: ['mister', 'woman', 'boy', 'girl']
        },
        {
          title: 'Enable',
          data: 'enable',
          type: 'checkbox'
        }
      ]
    },
    localItems() {
      return this.items || []
    }
  },
  mounted() {
    let hotInstance = this.$refs.hotRef['hotInstance']

    if (hotInstance) {
      this.$emit('update:instance', hotInstance)

      if (this.hooks) {
        for (let hookName in this.hooks) {
          let hookCallback = this.hooks[hookName]

          if (typeof hookCallback === 'function') {
            hotInstance.addHook(hookName, hookCallback)
          }
        }
      }

      hotInstance.validateCells()
    }
  }
}
</script>
