const ThePersonPage = () => import('@/views/person/view/ThePersonPage.vue')

export const personRootPages = [
  {
    path: '/person',
    children: [
      {
        path: ':personId',
        component: ThePersonPage,
        name: 'person_view_route',
        props: true
      }
    ]
  }
]
