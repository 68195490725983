export default {
  setMaterialResourceList(state, materialResourcesList) {
    state.materialResourceList = materialResourcesList
  },
  setMaterialResource(state, materialResource) {
    state.materialResource = materialResource
  },
  setMaterialResourceMetadata(state, metadata) {
    state.materialResourceMetadata = metadata
  },
  setMaterialResourceActivity(state, activity) {
    state.materialResourceActivity = activity
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  }
}
