<template>
  <div
    class="min-w-0 flex-1"
    :class="{
      'fixed inset-0 z-100 bg-white': fullscreen
    }"
  >
    <SettingsPageFull v-if="initialMetadata">
      <template #title>
        <div class="flex space-x-3">
          <span class="text-3xl font-bold capitalize-first">
            {{ $t('global.items_manage') }}
          </span>
        </div>
      </template>

      <template #cta>
        <div class="flex gap-2">
          <BaseButton
            color="secondary"
            :icon="fullscreen ? 'arrows-minimize' : 'arrows-maximize'"
            size="medium"
            @click.prevent="fullscreen = !fullscreen"
          />
          <BaseButton
            :title="$t('button.filter')"
            color="info"
            size="medium"
            icon="sliders"
            @click.prevent="toggleShowFilter"
          />
          <BaseButton
            :title="$t('button.back')"
            color="dark"
            icon="long-arrow-left"
            @click.prevent="goBack"
          />
          <ItemsExcelFilterModal
            v-model:visible="showFilter"
            v-model:lang="lang"
            v-model:item-category="itemCategoryId"
            v-model:funeral-home="funeralHomeId"
            v-model:supplier="supplierId"
            v-model:search="searchValue"
            :languages="localLanguages"
            :items-categories="metaItemCategories"
            :funeral-homes="funeralHomes"
            :suppliers="suppliers"
            :disabled="disableActions"
            @reload:items="reloadItems"
          />
        </div>
      </template>

      <template #body>
        <div
          v-if="!dataReady"
          class="min-h-0 flex-1 flex items-center justify-center"
        >
          <LoadingComponent :label="$t('global.loading_data')" />
        </div>
        <div v-else class="min-h-0 flex-1 flex flex-col gap-6">
          <div
            class="shrink-0 flex w-full flex-row justify-between gap-4 empty:hidden"
          >
            <div
              v-if="errorRows.length > 0"
              class="w-2/12 flex items-center justify-end"
            >
              <BaseButton
                :title="$t('global.errors') + ' (' + errorRows.length + ')'"
                color="danger"
                size="medium"
                @click.prevent="toggleShowErrors"
              />

              <ExcelErrorsModal
                v-model:visible="showErrors"
                :hot-instance="hotInstance"
                :rows="errorRows"
              />
            </div>
          </div>
          <div
            class="flex-1 min-h-0"
            :class="{
              'pointer-events-none opacity-40': disableActions
            }"
          >
            <ExcelManager
              v-model:instance="hotInstance"
              :sheet-name="'itemsList'"
              :columns="columns"
              :items="localItems"
              :settings="localSettings"
              :hooks="hooks"
              :disable-default="true"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <div v-if="dataReady" class="flex w-full justify-between">
          <div class="flex gap-2">
            <BaseButton
              color="info"
              icon="rotate-left"
              size="medium"
              :disabled="disableActions"
              @click.prevent="undo"
            />
            <BaseButton
              color="info"
              icon="rotate-right"
              size="medium"
              :disabled="disableActions"
              @click.prevent="redo"
            />
          </div>
          <div>
            <BaseButton
              :title="$t('button.save')"
              color="warning"
              icon="paper-plane"
              size="medium"
              :display-loader="disableActions"
              :disabled="disableActions"
              @click.prevent="onSave"
            />
          </div>
        </div>
      </template>
    </SettingsPageFull>
  </div>
</template>

<script>
import BasePaginationMixin from '@u/mixins/Pagination/BasePaginationMixin'
import SettingsPageFull from '@c/SettingsPageFull.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ExcelManager from '@c/Excel/ExcelManager.vue'
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@c/TheLoadingComponent.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import Handsontable from 'handsontable'
import BaseSettingsItemMixin from '@u/mixins/settings/items/BaseSettingsItemMixin'
import ExcelErrorsModal from '@c/Excel/ExcelErrorsModal.vue'
import ItemsExcelFilterModal from '@/views/settings/invoicing/item/manage/modal/ItemsExcelFilterModal.vue'

export default {
  name: 'TheItemManagePage',
  components: {
    ItemsExcelFilterModal,
    ExcelErrorsModal,
    SelectComponent,
    LoadingComponent,
    ExcelManager,
    BaseButton,
    SettingsPageFull
  },
  props: {},
  mixins: [BasePaginationMixin, BaseSettingsItemMixin],
  data() {
    return {
      fullscreen: false,
      hooks: {
        afterChange: this.afterChange.bind(this),
        afterValidate: this.afterValidate.bind(this)
      },
      showErrors: false,
      showFilter: false,
      disableActions: false,
      hotInstance: null,
      dataReady: false,
      funeralHomeId: null,
      itemCategoryId: null,
      supplierId: null,
      searchValue: null,
      pricingEncoding: null,
      lang: this.$i18n?.locale,
      languages: [],
      items: [],
      itemsSaved: [],
      itemsToRemove: [],
      suppliers: [],
      itemCategories: [],
      invoicingCategories: [],
      accountingCodes: [],
      errorRows: []
    }
  },
  methods: {
    ...mapActions({
      getItemForExcel: 'item/getItemForExcel',
      updateItemForExcel: 'item/updateItemForExcel'
    }),
    undo() {
      if (this.hotInstance && this.hotInstance.isUndoAvailable()) {
        this.hotInstance.undo()
      }
    },
    redo() {
      if (this.hotInstance && this.hotInstance.isRedoAvailable()) {
        this.hotInstance.redo()
      }
    },
    goBack() {
      this.fullscreen = false
      this.$router.push({ name: 'settings_list_item_route' })
    },
    toggleShowErrors() {
      this.showErrors = !this.showErrors
    },
    toggleShowFilter() {
      this.showFilter = !this.showFilter
    },
    afterChange(changes, source) {
      let sources = [
        'edit',
        'CopyPaste.paste',
        'CopyPaste.cut',
        'Autofill.fill'
      ]

      if (changes && sources.includes(source)) {
        for (let change of changes) {
          // Extract change data
          let [row, col, param, value] = change
          // Get cells meta of row
          let rowCells = this.hotInstance.getCellMetaAtRow(row)
          // Get cell meta from list
          let cellConfig = rowCells.find((cell) =>
            [cell.col, cell.prop].includes(col)
          )
          // Get current item
          let currentItem = this.items[row]

          if (cellConfig && currentItem) {
            // Get cell custom action
            let action = cellConfig.customProps?.action

            if (
              (action === 'htva_tvac' && this.encodingPriceHtva) ||
              (action === 'tvac_htva' && !this.encodingPriceHtva)
            ) {
              currentItem[cellConfig.customProps.target] = this.getPriceValue(
                value,
                currentItem[cellConfig.customProps.tva]
              )

              currentItem[cellConfig.customProps.tvaValue] = Math.abs(
                value - currentItem[cellConfig.customProps.target]
              )
            } else if (action === 'tva_update') {
              let sourcePrices = this.encodingPriceHtva
                ? cellConfig.customProps.htvaPrices
                : cellConfig.customProps.tvacPrices
              let targetPrices = this.encodingPriceHtva
                ? cellConfig.customProps.tvacPrices
                : cellConfig.customProps.htvaPrices
              let tvaValues = cellConfig.customProps.tvaValues

              for (let key in sourcePrices) {
                let tvaProp = tvaValues[key]
                let sourceProp = sourcePrices[key]
                let targetProp = targetPrices[key]

                currentItem[targetProp] = this.getPriceValue(
                  currentItem[sourceProp],
                  value
                )

                currentItem[tvaProp] = Math.abs(
                  currentItem[targetProp] - currentItem[sourceProp]
                )
              }
            }
          }
        }
      }
    },
    afterValidate(isValid, value, row, prop, source) {
      if (this.hotInstance) {
        // Get cells meta of row
        let rowCells = this.hotInstance.getCellMetaAtRow(row)
        // Get cell meta from list
        let cellConfig = rowCells.find((cell) =>
          [cell.col, cell.prop].includes(prop)
        )

        let foundRow = this.errorRows.find((r) => r.row === row)

        if (!isValid) {
          if (!foundRow) {
            foundRow = {
              row,
              cells: []
            }

            this.errorRows.push(foundRow)
          }

          foundRow.cells.push(cellConfig)
        } else {
          if (foundRow) {
            foundRow.cells = foundRow.cells.filter(
              (cell) => ![cell.col, cell.prop].includes(prop)
            )

            if (foundRow.cells.length === 0) {
              this.errorRows = this.errorRows.filter((rw) => rw !== foundRow)
            }
          }
        }
      }
    },
    onSave() {
      if (this.hotInstance) {
        this.disableActions = true
        this.errorRows = []

        this.hotInstance.validateCells((v) => {
          if (!v) {
            this.disableActions = false

            this.$h.toastWarning(
              'Warning',
              this.$t('validation.invalid_table_date')
            )
          } else {
            this.updateByParts()
          }
        })
      }
    },
    updateByParts(from = 0) {
      let increment = 500
      let to = from + increment

      if (to > this.items.length) {
        to = this.items.length - 1
      }

      if (this.items.length > from) {
        let items = this.items.slice(from, to)

        this.updateItemForExcel({
          funeralHome: this.funeralHomeId,
          items,
          itemsToRemove: this.itemsToRemove
            .filter((itm) => !!itm.id)
            .filter((itm) => !this.items.find((_itm) => _itm.id === itm.id))
            .map((itm) => itm.id)
        })
          .then((res) => {
            items.forEach((itm, k) => {
              let columns = Object.keys(itm)

              for (let col of columns) {
                if (itm[col] !== res.items[k][col]) {
                  itm[col] = res.items[k][col]
                }
              }
            })

            this.updateByParts(from + increment)
          })
          .catch((e) => {
            this.$h.toastWarning(
              'Warning',
              this.$t('validation.invalid_table_date')
            )

            this.disableActions = false
          })
      } else {
        this.disableActions = false
        this.itemsToRemove = [...this.items]

        this.$h.toastSuccess('Success', this.$t('global.items_update_success'))
      }
    },
    reloadItems(response) {
      if (this.showFilter) {
        this.showFilter = false
      }

      if (response) {
        this.loadItems()
      } else {
        if (this.errorRows.length > 0) {
          this.errorRows = []
        }

        this.dataReady = false

        setTimeout(() => (this.dataReady = true), 300)
      }
    },
    loadItems(payload = {}) {
      if (this.dataReady) {
        this.dataReady = false
        this.items = []
        this.itemsToRemove = []
        this.errorRows = []
      }

      if (this.funeralHomeId) {
        if (!payload.funeralHome) {
          payload.funeralHome = this.funeralHomeId
        }
      }

      if (this.itemCategoryId) {
        if (!payload.category) {
          payload.category = this.itemCategoryId
        }
      }

      if (this.supplierId) {
        if (!payload.supplier) {
          payload.supplier = this.supplierId
        }
      }

      if (this.searchValue) {
        if (!payload.search) {
          payload.search = this.searchValue
        }
      }

      this.getItemForExcel(payload).then((data) => {
        this.items.push(...data.items)
        this.itemsToRemove.push(...data.items)

        if (data.suppliers) {
          this.suppliers = data.suppliers
        }

        if (data.itemCategories) {
          this.itemCategories = data.itemCategories
        }

        if (data.invoicingCategories) {
          this.invoicingCategories = data.invoicingCategories
        }

        if (data.accountingCodes) {
          this.accountingCodes = data.accountingCodes
        }

        if (data.languages) {
          this.languages = data.languages
          let indexOf = this.languages.indexOf(this.$i18n?.locale)

          if (indexOf > -1) {
            this.lang = this.languages[indexOf]
          } else {
            this.lang = this.languages[0]
          }
        }

        if (data.pricingEncoding) {
          this.pricingEncoding = data.pricingEncoding
        }

        if (data.nextOffset) {
          this.loadItems({
            funeralHome: this.funeralHomeId,
            offset: data.nextOffset
          })
        } else {
          this.dataReady = true
        }
      })
    },
    renderLabelModel(
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties,
      models,
      targetProp = 'id'
    ) {
      let cellData = [
        hotInstance,
        td,
        row,
        column,
        prop,
        models.find((sp) => sp[targetProp] === value)?.name,
        cellProperties
      ]

      Handsontable.renderers.HandsontableRenderer.apply(this, cellData)
    },
    getPriceValue(price, tva) {
      let value

      if (this.encodingPriceHtva) {
        value = price * (1 + tva / 100)
      } else {
        value = price / (1 + tva / 100)
      }

      return parseFloat(parseFloat(value).toFixed(2))
    }
  },
  computed: {
    ...mapGetters({
      funeralHomes: 'metadata/getFuneralHomesMetadata',
      metaItemCategories: 'metadata/getMetadataItemCategories',
      vatRates: 'metadata/getVatRatesMetadata',
      normalizedCategories: 'metadata/getMetadataCategoriesNormalized',
      getDefaultCountry: 'metadata/getDefaultCountry',
      getMetadataNormalizedItemTypes: 'metadata/getMetadataNormalizedItemTypes'
    }),
    localItems() {
      let items = this.items || []
      let searchValue = this.searchValue?.toLowerCase()

      if (searchValue) {
        return items.filter((item, key) => {
          if (key === items.length - 1) {
            return false
          }

          let values = [
            item.name?.[this.$i18n.locale] || '',
            item.remarks?.[this.$i18n.locale] || '',
            item.ref || '',
            item.default_supplier_ref || '',
            this.itemCategories.find((c) => c.id === item.item_category_id)
              ?.name || '',
            this.invoicingCategories.find(
              (c) => c.id === item.invoicing_category_id
            )?.name || '',
            item.default_price_tvac?.toString() || '',
            // Items prices
            item.default_price_htva?.toString() || '',
            item.default_price_tva_value?.toString() || '',
            item.default_fixed_price_tvac?.toString() || '',
            item.default_fixed_price_htva?.toString() || '',
            item.default_fixed_price_tva_value?.toString() || '',
            item.default_purchase_price_tvac?.toString() || '',
            item.default_purchase_price_htva?.toString() || '',
            item.default_purchase_price_tva_value?.toString() || '',
            // Specific prices
            item.specific_fixed_price_htva?.toString() || '',
            item.specific_fixed_price_tva_value?.toString() || '',
            item.specific_fixed_price_tvac?.toString() || '',
            item.specific_price_htva?.toString() || '',
            item.specific_price_tva_value?.toString() || '',
            item.specific_price_tvac?.toString() || '',
            item.specific_purchase_price_htva?.toString() || '',
            item.specific_purchase_price_tva_value?.toString() || '',
            item.specific_purchase_price_tvac?.toString() || ''
          ].filter((itm) => !!itm && itm.toLowerCase().includes(searchValue))

          return values.length > 0
        })
      }

      return items
    },
    encodingPriceHtva() {
      return this.pricingEncoding === 'htva'
    },
    localLanguages() {
      return this.languages.map((lang) => ({
        id: lang,
        name: lang
      }))
    },
    localSettings() {
      let settings = {
        fixedColumnsStart: 1
      }

      if (this.funeralHomeId) {
        settings.contextMenu = ['copy', 'undo', 'redo']
      }

      return settings
    },
    defaultColumns() {
      return [
        {
          title: this.$t('attributes.name'),
          type: 'text',
          data: `name.${this.lang}`,
          readOnly: !!this.funeralHomeId,
          className: 'w-[250px]',
          validator: !this.funeralHomeId ? 'not-empty' : undefined
        },
        {
          title: this.$t('attributes.item_category_id'),
          type: 'handsontable',
          data: 'item_category_id',
          readOnly: !!this.funeralHomeId,
          validator: !this.funeralHomeId ? 'not-empty' : undefined,
          renderer: (...args) => {
            this.renderLabelModel(...args, this.itemCategories)
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.itemCategories,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
            }
          }
        },
        {
          title: this.$t('attributes.reference'),
          type: 'text',
          data: 'ref',
          readOnly: !!this.funeralHomeId,
          allowEmpty: false
        },
        {
          title: this.$t('global.variant'),
          data: 'variant',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false,
          readOnly: true
        }
      ]
    },
    localNormalizedCategories() {
      return this.normalizedCategories.map((itm) => ({
        ...itm,
        id: itm.id.toString()
      }))
    },
    localOrderMode() {
      return [
        { id: 'NO_ORDER', name: this.$t('global.no_order') },
        { id: 'IN_ORDER', name: this.$t('global.in_order') },
        { id: 'ORDERED', name: this.$t('global.ordered_choice') }
      ]
    },
    funeralHomeItemsColumns() {
      return [
        ...this.defaultColumns,
        {
          title: this.$t('attributes.specific_supplier_ref'),
          type: 'text',
          data: 'specific_supplier_ref',
          readOnly: false
        },
        {
          title: this.$t('attributes.specific_accounting_code'),
          type: 'text',
          data: 'specific_accounting_code',
          readOnly: false
        },
        {
          title: this.$t('attributes.specific_invoicing_label'),
          type: 'text',
          data: `specific_invoicing_label.${this.lang}`,
          readOnly: false
        },
        {
          title: this.$t('attributes.remarks'),
          type: 'text',
          data: `specific_invoicing_remarks.${this.lang}`
        },
        {
          title: this.$t('button.hide'),
          data: 'specific_hidden',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.specific_reduction'),
          type: 'numeric',
          data: 'specific_reduction',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'fr-FR' // this is the default culture, set up for USD
          },
          readOnly: false
        },
        {
          title: this.$t('attributes.specific_tva'),
          type: 'dropdown',
          source: this.vatRates,
          data: 'specific_tva',
          readOnly: false,
          customProps: {
            // The action to update
            action: 'tva_update',
            htvaPrices: [
              'specific_fixed_price_htva',
              'specific_price_htva',
              'specific_purchase_price_htva'
            ],
            tvacPrices: [
              'specific_fixed_price_tvac',
              'specific_price_tvac',
              'specific_purchase_price_tvac'
            ],
            tvaValues: [
              'specific_fixed_price_tva_value',
              'specific_price_tva_value',
              'specific_purchase_price_tva_value'
            ]
          }
        },
        {
          title: this.$t('attributes.specific_fixed_price_htva'),
          data: 'specific_fixed_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'specific_fixed_price_tvac',
            // The cell tva result value
            tvaValue: 'specific_fixed_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        },
        {
          title: this.$t('attributes.specific_fixed_price_tva_value'),
          data: 'specific_fixed_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.specific_fixed_price_tvac'),
          data: 'specific_fixed_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'specific_fixed_price_htva',
            // The cell tva result value
            tvaValue: 'specific_fixed_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        },
        {
          title: this.$t('attributes.specific_price_htva'),
          data: 'specific_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'specific_price_tvac',
            // The cell tva result value
            tvaValue: 'specific_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        },
        {
          title: this.$t('attributes.specific_price_tva_value'),
          data: 'specific_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.specific_price_tvac'),
          data: 'specific_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'specific_price_htva',
            // The cell tva result value
            tvaValue: 'specific_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        },
        {
          title: this.$t('attributes.specific_purchase_price_htva'),
          data: 'specific_purchase_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'specific_purchase_price_tvac',
            // The cell tva result value
            tvaValue: 'specific_purchase_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        },
        {
          title: this.$t('attributes.specific_purchase_price_tva_value'),
          data: 'specific_purchase_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.specific_purchase_price_tvac'),
          data: 'specific_purchase_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'specific_purchase_price_htva',
            // The cell tva result value
            tvaValue: 'specific_purchase_price_tva_value',
            // The cell tva value
            tva: 'specific_tva'
          }
        }
      ]
    },
    itemsColumns() {
      let columns = [
        ...this.defaultColumns,
        {
          title: this.$t('attributes.remarks'),
          type: 'text',
          data: `remarks.${this.lang}`
        },
        {
          title: this.$t('attributes.type'),
          type: 'handsontable',
          data: 'type',
          renderer: (...args) => {
            this.renderLabelModel(...args, this.orderedItemTypes)
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.orderedItemTypes,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
            }
          }
        },
        {
          title: this.$t('attributes.invoicing_category_id'),
          type: 'handsontable',
          data: 'invoicing_category_id',
          renderer: (...args) => {
            this.renderLabelModel(...args, this.invoicingCategories)
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.invoicingCategories,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
            }
          },
          validator: 'not-empty'
        }
      ]

      if (this.isDefaultCountryFr) {
        columns.push(
          {
            title: this.$t('global.category_devis_normalised'),
            type: 'handsontable',
            data: 'category_devis_normalised',
            validator: 'not-empty',
            renderer: (...args) => {
              this.renderLabelModel(...args, this.localNormalizedCategories)
            },
            handsontable: {
              columns: [
                {
                  type: 'text',
                  data: 'name'
                }
              ],
              autoColumnSize: true,
              data: this.localNormalizedCategories,
              getValue() {
                const selection = this.getSelectedLast()

                return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
              }
            }
          },
          {
            title: this.$t('attributes.item_type_devis_normalised'),
            type: 'handsontable',
            data: 'item_type_devis_normalised',
            validator: 'not-empty',
            renderer: (...args) => {
              this.renderLabelModel(
                ...args,
                this.getMetadataNormalizedItemTypes
              )
            },
            handsontable: {
              columns: [
                {
                  type: 'text',
                  data: 'name'
                }
              ],
              autoColumnSize: true,
              data: this.getMetadataNormalizedItemTypes,
              getValue() {
                const selection = this.getSelectedLast()

                return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
              }
            }
          }
        )
      }

      columns.push(
        {
          title: this.$t('attributes.order_mode'),
          type: 'handsontable',
          data: 'order_mode',
          renderer: (...args) => {
            this.renderLabelModel(...args, this.localOrderMode)
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.localOrderMode,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
            }
          }
        },
        {
          title: this.$t('attributes.default_supplier_ref'),
          type: 'text',
          data: 'default_supplier_ref',
          readOnly: false
        },
        {
          title: this.$t('attributes.supplier'),
          type: 'handsontable',
          data: 'supplier_id',
          renderer: (...args) => {
            this.renderLabelModel(...args, this.suppliers)
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.suppliers,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).id
            }
          }
        },
        {
          title: this.$t('attributes.default_accounting_code'),
          type: 'handsontable',
          data: 'default_accounting_code',
          renderer: (...args) => {
            this.renderLabelModel(...args, this.accountingCodes, 'code')
          },
          handsontable: {
            columns: [
              {
                type: 'text',
                data: 'name'
              }
            ],
            autoColumnSize: true,
            data: this.accountingCodes,
            getValue() {
              const selection = this.getSelectedLast()

              return this.getSourceDataAtRow(Math.max(selection[0], 0)).code
            }
          },
          readOnly: false
        },
        {
          title: this.$t('attributes.stockable'),
          data: 'stockable',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.catalogue'),
          data: 'catalogue',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.burial'),
          data: 'burial',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.cremation'),
          data: 'cremation',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.has_fixe_price'),
          data: 'has_fixe_price',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.favorite'),
          data: 'favorite',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.visible'),
          data: 'visible',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.is_third_party_cost'),
          data: 'is_third_party_cost',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.supplier_locked'),
          data: 'supplier_locked',
          type: 'checkbox',
          checkedTemplate: true,
          uncheckedTemplate: false
        },
        {
          title: this.$t('attributes.default_tva'),
          type: 'dropdown',
          source: this.vatRates,
          data: 'default_tva',
          readOnly: false,
          customProps: {
            // The action to update
            action: 'tva_update',
            htvaPrices: [
              'default_price_htva',
              'default_fixed_price_htva',
              'default_purchase_price_htva'
            ],
            tvacPrices: [
              'default_price_tvac',
              'default_fixed_price_tvac',
              'default_purchase_price_tvac'
            ],
            tvaValues: [
              'default_price_tva_value',
              'default_fixed_price_tva_value',
              'default_purchase_price_tva_value'
            ]
          },
          validator: 'not-empty'
        },
        {
          title: this.$t('attributes.default_price_htva'),
          data: 'default_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'default_price_tvac',
            // The cell tva result value
            tvaValue: 'default_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        },
        {
          title: this.$t('attributes.default_price_tva_value'),
          data: 'default_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.default_price_tvac'),
          data: 'default_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'default_price_htva',
            // The cell tva result value
            tvaValue: 'default_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        },
        {
          title: this.$t('attributes.default_fixed_price_htva'),
          data: 'default_fixed_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'default_fixed_price_tvac',
            // The cell tva result value
            tvaValue: 'default_fixed_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        },
        {
          title: this.$t('attributes.default_fixed_price_tva_value'),
          data: 'default_fixed_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.default_fixed_price_tvac'),
          data: 'default_fixed_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'default_fixed_price_htva',
            // The cell tva result value
            tvaValue: 'default_fixed_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        },
        {
          title: this.$t('attributes.default_purchase_price_htva'),
          data: 'default_purchase_price_htva',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: !this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'htva_tvac',
            // The cell to update
            target: 'default_purchase_price_tvac',
            // The cell tva result value
            tvaValue: 'default_purchase_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        },
        {
          title: this.$t('attributes.default_purchase_price_tva_value'),
          data: 'default_purchase_price_tva_value',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: true
        },
        {
          title: this.$t('attributes.default_purchase_price_tvac'),
          data: 'default_purchase_price_tvac',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0.00',
            culture: 'tr-TR'
          },
          readOnly: this.encodingPriceHtva,
          customProps: {
            // The action to update
            action: 'tvac_htva',
            // The cell to update
            target: 'default_purchase_price_htva',
            // The cell tva result value
            tvaValue: 'default_purchase_price_tva_value',
            // The cell tva value
            tva: 'default_tva'
          }
        }
      )

      return columns
    },
    /**
     *
     * @return {any[]}
     */
    columns() {
      return this.funeralHomeId
        ? this.funeralHomeItemsColumns
        : this.itemsColumns
    }
  },
  mounted() {
    this.loadItems()
  }
}
</script>
