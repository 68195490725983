const WikiCityCeremonyFormsTabsComponent = () =>
  import(
    '@/views/wiki/communes/form/tabs/ceremony/form/WikiCityCeremonyFormsTabsComponent.vue'
  )
const WikiCityCeremonyInformation = () =>
  import(
    '@/views/wiki/communes/form/tabs/ceremony/form/tabs/information/WikiCityCeremonyInformation.vue'
  )
const WikiCityCeremonyIndexPage = () =>
  import(
    '@/views/wiki/communes/form/tabs/ceremony/index/WikiCityCeremonyIndexPage.vue'
  )
const WikiPlaceItemsTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceItemsTab.vue')
const WikiPlaceContactTab = () =>
  import('@/views/wiki/communes/form/tabs/components/WikiPlaceContactTab.vue')
const WikiPlaceTranslationTab = () =>
  import(
    '@/views/wiki/communes/form/tabs/components/WikiPlaceTranslationTab.vue'
  )

export const wikiCityCeremonyRoutes = [
  {
    path: 'ceremony',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_ceremony_index_page_route',
        props: true,
        component: WikiCityCeremonyIndexPage,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':ceremony_id',
        name: 'wiki_city_edit_ceremony_page_route',
        component: WikiCityCeremonyFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_ceremony_information_page_route',
            component: WikiCityCeremonyInformation,
            props: true
          },
          {
            path: 'translation',
            name: 'wiki_city_edit_ceremony_translation_page_route',
            component: WikiPlaceTranslationTab,
            props: true
          },
          {
            path: 'contact',
            name: 'wiki_city_edit_ceremony_contact_page_route',
            component: WikiPlaceContactTab,
            props: true
          },
          {
            path: 'items',
            name: 'wiki_city_edit_ceremony_items_page_route',
            component: WikiPlaceItemsTab,
            props: true
          }
        ],
        meta: {
          type: 'edit'
        }
      },
      {
        path: 'new',
        name: 'wiki_city_create_ceremony_page_route',
        component: WikiCityCeremonyFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_create_ceremony_information_page_route',
            component: WikiCityCeremonyInformation
          },
          {
            path: 'translation',
            name: 'wiki_city_create_ceremony_translation_page_route',
            component: WikiPlaceTranslationTab
          },
          {
            path: 'contact',
            name: 'wiki_city_create_ceremony_contact_page_route',
            component: WikiPlaceContactTab
          },
          {
            path: 'items',
            name: 'wiki_city_create_ceremony_items_page_route',
            component: WikiPlaceItemsTab
          }
        ],
        meta: {
          type: 'create'
        }
      }
    ]
  }
]
