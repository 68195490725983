import TheSaManagementPage from '../../views/sa_management/TheSaManagementPage'
import { saManagementGeneralRoutes } from '@/router/sa_management/general'
const PlaygoundComponent = () => import('@/views/wip/PlaygoundComponent.vue')

export const saManagementSubPages = [
  {
    path: '/sa-management',
    name: 'management_index_route',
    component: TheSaManagementPage,
    children: [...saManagementGeneralRoutes]
  },
  {
    path: '/playground',
    name: 'playground',
    component: PlaygoundComponent
  }
]
