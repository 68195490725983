export default {
  setPackList(state, packList) {
    state.PackList = packList
  },
  setPack(state, pack) {
    state.pack = pack
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setPackActivity(state, activity) {
    state.packActivity = activity
  },
  setMetadataActivities(state, metadata) {
    state.metadataActivities = metadata
  },
  setEditionMode(state, editionMode) {
    state.editionMode = editionMode
  },
  removeItemFromPack(state, index) {
    state.pack.items.splice(index, 1)
  },
  addItemToPack(state, payload) {
    state.pack.items.push({
      ...payload.item.pivot,
      item: payload.item
    })
  }
}
