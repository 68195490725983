<template>
  <div class="flex flex-col divide-y rounded-lg bg-white px-4">
    <div class="flex shrink-0 flex-row items-center py-4">
      <div class="flex flex-1 flex-col">
        <div class="font-bold">
          {{ $h.formatDateAndDiff(activity.created_at, 'LLLL') }}
        </div>
        <div class="font-semibold">
          # {{ $t(`enums.activityLog.type.${activity.description}`) }}
        </div>
      </div>
      <div class="flex shrink-0 flex-col">
        <div class="flew-row flex flex-1 items-center justify-end gap-2">
          <span class="font-semibold">{{ user?.full_name }}</span>
          <UserAvatarPopover :user-id="activity.causer_id" size="extra-small" />
        </div>
      </div>
    </div>
    <div v-if="propertiesAsArray.length" class="py-4">
      <table class="w-full">
        <tr
          v-for="property in propertiesAsArray"
          :key="property.id"
          class="odd:bg-gray-100"
        >
          <td class="w-1/3 py-2 pl-3 font-bold">
            {{ $t(`attributes.${property.attribute}`) }}
          </td>
          <td class="w-1/3 py-2">{{ property.old }}</td>
          <td class="w-1/3 py-2">{{ property.new }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import UserAvatarPopover from '@c/Avatar/Popover/UserAvatarPopover.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ActivityBlock',
  components: { UserAvatarPopover },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getAllUserMetadata'
    }),
    user() {
      return this.users(this.activity.causer_id)
    },
    propertiesAsArray() {
      if (!this.activity.properties.attributes) {
        return []
      }
      return Object.keys(this.activity.properties.attributes).map((key) => ({
        attribute: key,
        old: this.activity.properties.old?.[key] ?? '---',
        new: this.activity.properties.attributes[key]
      }))
    }
  }
}
</script>
