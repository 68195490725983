<template>
  <BaseCenteredModal
    :title="''"
    :large="false"
    :translucent="false"
    :is-modal-open="open"
    @close="$emit('close')"
  >
    <template #body>
      <div class="flex flex-col w-full gap-y-3 p-5">
        <SelectComponent
          v-model="sendRecord.send_type"
          :name="name"
          :label="$t('attributes.send_type')"
          :values="initialMetadata?.enums?.send_types"
          :errors="errors?.send_type"
          display-key="name"
          edition-mode
          required
        />
        <div v-if="error_send_record" class="-mt-3 ml-3 text-red-600 text-xs">{{ $t('validation.error_send_record')}}</div>
        <BaseShowEditTextArea
          v-model="sendRecord.remarks"
          name="remarks"
          :label="$t('attributes.internal_notes')"
          :edition-mode="true"
          :errors="errors?.remarks"
        />
        <div class="flex justify-end gap-x-1">
          <BaseButton
            :title="$t('button.cancel')"
            color="warning"
            @click="$emit('close')"
          />
          <BaseButton
            :title="$t('button.confirm')"
            :disabled="!sendRecord.send_type"
            @click="handleSendRecordConfirm"
          />
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import { defineComponent } from 'vue'
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectComponent from "@c/BaseFormComponent/SelectComponent.vue";
import BaseShowEditTextArea from "@c/addf-package/components/BaseShowEditInput/BaseShowEditTextArea.vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'SendRecordInfoDialogModal',
  components: {BaseShowEditTextArea, SelectComponent, BaseButton, BaseCenteredModal},
  props: {
    sendRecord: {
      type: Object,
      default: null
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  emits: ['add-send-record-confirm', 'close'],
  data() {
    return {
      error_send_record: false
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata',
      errors: 'auth/getErrors'
    }),
  },
  methods: {
    handleSendRecordConfirm() {
      if(!this.sendRecord.send_type) {
        this.error_send_record = true
      } else {
        this.$emit('add-send-record-confirm')
        this.error_send_record = false
      }

    }
  },
})
</script>
