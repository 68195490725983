import apiClient from '@u/apiClient'

const baseErrors = {}

export default {
  deletePerson({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`person/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  undoDeletePerson: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`person/${payload.id}/undo-delete`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region fetch

  fetchPersonList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`person`, { params: payload.params })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchPersonById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`person/${payload.person_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchPersonByIdWithAllRelations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`person/${payload.person_id}/relations`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchNewPerson({ commit }, predefinedRole) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`model/new?model=person`)
        .then((response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  storePerson({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post('person', payload.person)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updatePerson({ commit }, payload) {
    commit('auth/setErrors', baseErrors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`person/${payload.id}`, payload.person)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  linkPersonToChiftCustomer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`person/${payload.person_id}/chift/link`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
