import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      invoiceIndex: [],
      invoice: {
        dossier: {},
        contact: {}
      },
      invoiceItem: {},
      invoiceItems: [],
      invoiceActivity: [],
      invoiceMetadata: [],
      invoiceItemsMetadata: [],
      checkDepositList: [],
      checkListByType: [],
      checkDeposit: {},
      checkMetadata: {},
      payment: {},
      personList: [],
      paymentMetadata: {},
      sendInvoiceData: {
        receivers: null,
        subject: null,
        messageConverted: ''
      },
      filters: [],
      metadataActivities: [],
      checkDataStatus: null,

      online_members: []
    }
  },
  getters: {
    ...getters
  },
  actions,
  mutations: {
    ...mutations
  }
}
