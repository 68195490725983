import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      dossiersList: [],
      personList: [],
      person: {},
      dossier: { concernedPerson: {}, properties: {}, contacts: [] },
      dossierActivities: [],
      dossierMetadataActivities: [],
      dossierMetadata: {},
      contactsDossier: [],
      editionMode: false,
      leaveRouteByButton: false,
      leaveRouteByNotification: false,
      initialDossier: null,
      hasChanged: true,
      navSubItems: [],
      partnerData: {},
      partnerSharing: {},
      partnerSharingErrors: {},
      documentsDossier: [],
      online_members: []
    }
  },
  getters: {
    ...getters
  },
  actions,
  mutations: {
    ...mutations
  }
}
