import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'
import apiClient from '@u/apiClient'
import apiClientDocument from '@u/apiClientDocument'

// Private instance of VueI18n object
let _i18n

// Initializer
function setup(options = { locale: 'default' }) {
  _i18n = createI18n({
    locale: options.locale || 'default',
    fallbackLocale: 'default'
  })
  setLocale(options.locale).then((_) => {
    return _i18n
  })
}

function setLocale(locale) {
  return new Promise((resolve, reject) => {
    // si les messages sont déjà loadé dans i18n
    if (
      locale !== 'default' &&
      _i18n.global.availableLocales.includes(locale)
    ) {
      // on change la locale courante dans i18n
      _i18n.global.locale = locale
      // on change le header X-Locale pour les requêtes axios
      apiClient.defaults.headers.common['X-Locale'] = locale
      apiClientDocument.defaults.headers.common['X-Locale'] = locale
      // on change le lang dans l'HTML
      document.querySelector('html').setAttribute('lang', locale)
      return nextTick(() => {
        resolve()
      })
    } else {
      apiClient
        .get(`local/translations/${locale}`)
        .then((response) => {
          if (_i18n.global.fallbackLocale === 'default') {
            _i18n.global.fallbackLocale = response.data.local
          }
          // on pousse les messages dans i18n
          _i18n.global.setLocaleMessage(
            response.data.local,
            response.data.translations
          )
          // on change la locale courante dans i18n
          _i18n.global.locale = response.data.local
          // on change le header X-Locale pour les requêtes axios
          apiClient.defaults.headers.common['X-Locale'] = response.data.local
          apiClientDocument.defaults.headers.common['X-Locale'] =
            response.data.local
          // on change le lang dans l'HTML
          document
            .querySelector('html')
            .setAttribute('lang', response.data.local)
          return nextTick(() => {
            resolve()
          })
        })
        .catch((reason) => {
          alert(reason)
        })
    }
  })
}

// Public interface
export default {
  get vueI18n() {
    return _i18n
  },
  setup,
  setLocale
}
