const TheMarketPlacePage = () =>
  import('@/views/marketplace/TheMarketPlacePage.vue')
const TheMarketPlaceListPage = () =>
  import('@/views/marketplace/list/TheMarketPlaceListPage.vue')
const TheMarketPlaceModulePage = () =>
  import('@/views/marketplace/list/TheMarketPlaceModulePage.vue')

export const marketplacePages = [
  {
    path: '/marketplace',
    name: 'marketplace_page_route',
    component: TheMarketPlacePage,
    children: [
      {
        path: '',
        name: 'marketplace_list',
        component: TheMarketPlaceListPage
      },
      {
        path: ':optionId',
        name: 'marketplace_option_info',
        component: TheMarketPlaceModulePage,
        props: true
      }
    ]
  }
]
