import apiClient from '@u/apiClient'

import importActions from './actions.import'

export default {
  ...importActions,

  fetchDumpList({}) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/sa-management/dump`)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchReleaseList({}) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/sa-management/release`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchRelease({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/sa-management/release/${payload.release_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createRelease({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/sa-management/release`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateRelease({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`/sa-management/release/${payload.release_id}`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createRelease({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`/sa-management/release/`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async dumpDb({ commit, getters }, payload) {
    console.log('dumpDb')
    await apiClient
      .get(`/sa-management/dump/direct`)
      .then(async (response) => {
        // console.log('res', response)
        if (response.data.success) {
          payload.successCallback()
        }
      })
      .catch(async (reason) => {
        console.error('fetchDumpList', reason)
      })
  },

  async restoreDump({ commit, getters }, payload) {
    console.log('in restore')
    await apiClient
      .post(`/sa-management/dump/restore`, { dump: payload.dump })
      .then(async (response) => {
        if (response.status === 200) {
          payload.successCallback(response.data.key)
        }
      })
      .catch(async (reason) => {
        console.error('fetchDumpList', reason)
      })
  }
}
