import apiClient from '@u/apiClient'

export default {
  // region fetch
  fetchAllVersionNotes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('version-note')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
  // endregion
}
