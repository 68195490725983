import apiClient from '@u/apiClient'

// const base_errors = { deceased: {}, events: [] }
const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // #region fetch
  fetchPartnerData({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `partners/fetch-${payload.partner}/${payload.dossier_id}`,
          payload
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // #endregion
  // #region fragment
  fetchFragment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `dossier/${payload.dossier_id}/fragment?force-reload=${payload.force_reload}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchFragmentInformations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `dossier/${payload.dossier_id}/fragment/informations?force-reload=${payload.force_reload}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createFragment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/fragment`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchFunebra({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/funebra`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createFunebra({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/funebra`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchCybille({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/cybille`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createCybille({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/cybille`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchIAssure({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/iassure`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  saveIAssure({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/iassure`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchSorenir({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/sorenir`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createSorenir({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/sorenir`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchFuneUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/funeup`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createFuneUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/funeup`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchEnaos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`dossier/${payload.dossier_id}/enaos`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchEnaosStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(
          `dossier/${payload.dossier_id}/enaos/status?force-reload=${payload.force_reload}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchEnaosTicket({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`funeral-home/${payload.funeral_home_id}/enaos/ticket`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createEnaos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`dossier/${payload.dossier_id}/enaos`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updateEnaos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/${payload.dossier_id}/enaos`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  submitPartnerData({ commit }, obj) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(obj.path, obj.partnerData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  updatePartnerData({ commit }, obj) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(obj.path, obj.partnerData)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async submitDocumentToEnaos({ commit }, obj) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient
      .post(`/send-attachment-enaos/${obj.dossier_id}`, obj)
      .then(async (response) => {
        console.log('response', response)
        if (response.status === 201) {
          if (obj.successCallback) {
            obj.successCallback()
          }
        }
      })
      .catch(async (reason) => {
        await commit('setErrors', reason)
      })
  },

  // #endregion

  // #region update

  async updateDossierTask({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(`task-dossier/${payload.id}`, payload.task)
      .then(async (response) => {
        if (response.status === 200) {
          if (!payload.dontCommit) {
            commit(
              'dossier/updateDossierTaskInDossier',
              response.data.dossierTask
            )
            commit('dossier/setSelectedDossierTask', response.data.dossierTask)
          }
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await commit('setErrors', reason)
      })
  },

  async updateDossierTaskStatus({ commit, state }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .put(
        `task-dossier/update-status/${payload.id}?new_status=${payload.new_status}`
      )
      .then((response) => {
        if (response.status === 200) {
          processSuccessCallback(payload)
          if (payload.isItemTask) {
            commit('setDossier', response.data.dossier)
          } else {
            commit('setDossier', response.data.dossier)
            if (state.selectedDossierTask) {
              if (
                state.selectedDossierTask.parent_type === 'App\\Models\\Dossier'
              ) {
                commit(
                  'setSelectedDossierTask',
                  response.data.dossier.dossier_tasks.find(
                    (dossier_task) =>
                      dossier_task.id === state.selectedDossierTask.id
                  )
                )
              } else if (
                state.selectedDossierTask.parent_type === 'App\\Models\\Event'
              ) {
                commit(
                  'setSelectedDossierTask',
                  response.data.dossier.events
                    .find(
                      (event) =>
                        event.id === state.selectedDossierTask.parent_id
                    )
                    ?.dossier_tasks.find(
                      (dossier_task) =>
                        dossier_task.id === state.selectedDossierTask.id
                    )
                )
              }
            }
          }
        }
      })
      .catch((reason) => {
        console.error('updateDossierTaskStatus', reason)
      })
  }

  // #endregion
}
