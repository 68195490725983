<template>
  <div>
    <div
      class="dark:border-1 relative flex flex-row rounded-md border border-slate-300 shadow-sm focus-within:border-primary-400 focus-within:ring-0 dark:border dark:border-white"
      :class="{
        'mt-2': !!label
      }"
    >
      <BaseLabelTop
        :target="name"
        :label="label"
        :disabled="disabled"
        :required="required"
      />

      <div
        class="dark:border-1 flex w-full flex-row rounded-md dark:border-white dark:bg-slate-500 dark:text-white"
        :class="{
          'bg-white': !disabled,
          'bg-gray-100': disabled
        }"
      >
        <Listbox v-model="selectedValues" :disabled="disabled">
          <Float
            adaptive-width
            :offset="2"
            :portal="true"
            :auto-placement="{
              autoAlignment: true,
              allowedPlacements: ['bottom', 'top']
            }"
          >
            <ListboxButton
              class="flex min-w-0 flex-1 flex-row border-0 p-0 px-3 text-gray-900 placeholder-gray-500 outline-0 dark:rounded-sm dark:border-white dark:bg-slate-500 dark:text-gray-300 sm:text-sm"
              :class="sizeClass"
            >
              <div
                class="flex min-w-0 flex-1 flex-row items-center gap-1.5 truncate"
              >
                <div v-if="modelValue && colored" class="shrink-0">
                  <div
                    v-if="!selectedValuesIcon"
                    :data-tint="selectedValuesBgColor"
                    class="block h-3 w-3 rounded-full bg-primary"
                  ></div>
                  <div
                    v-else
                    :data-tint="selectedValuesBgColor"
                    class="flex h-4 w-4 flex-row items-center justify-center rounded bg-primary text-contrast-primary"
                  >
                    <FontAwesomeIcon
                      :icon="['fal', selectedValuesIcon]"
                      size="xs"
                    />
                  </div>
                </div>
                <div
                  class="flex-1 truncate text-left"
                  :class="[capitalize ? 'capitalize-first' : '']"
                  v-html="selectedValuesLabel"
                ></div>
              </div>
              <div class="inset-y-0 right-2 flex items-center">
                <div class="pointer-events-none">
                  <FontAwesomeIcon :icon="['fal', 'chevron-down']" />
                </div>
                <button
                  v-if="!required && selectedValues && !disabled"
                  type="button"
                  tabindex="-1"
                  class="flex-shrink-0 pl-3 text-red-600"
                  @click.prevent="$emit('update:modelValue', null)"
                >
                  <FontAwesomeIcon :icon="['far', 'times']"></FontAwesomeIcon>
                </button>
              </div>
            </ListboxButton>
            <ListboxOptions
              class="dark:border-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white dark:bg-slate-500 dark:text-gray-300 sm:text-sm"
            >
              <div v-if="orderedValues.length === 0" class="px-4 py-2">
                {{ $t('global.is_empty_list') }}
              </div>
              <ListboxOption
                v-for="value in orderedValues"
                v-else
                v-slot="{ active, selected, disabled }"
                :key="value"
                :value="value[trackBy]"
                as="div"
                :disabled="value?.disabled"
              >
                <li
                  class="dark:border-1 relative cursor-pointer select-none py-2 pl-8 pr-4 dark:border-white"
                  :class="{
                    'bg-gray-200 text-gray-700 dark:text-white': disabled,
                    'bg-primary-500 text-contrast-primary dark:text-white':
                      selected,
                    'bg-primary-300 text-white dark:text-white ': active,
                    'text-gray-900 dark:text-white': !active && !selected
                  }"
                >
                  <slot
                    name="option"
                    :option="value"
                    :active="active"
                    :selected="selected"
                  >
                    <span
                      class="flex w-full flex-row items-center gap-1.5 truncate"
                    >
                      <span v-if="colored" class="shrink-0">
                        <span
                          v-if="!value.icon"
                          :data-tint="value.bg_color ?? value.tint"
                          class="block h-3 w-3 rounded-full bg-primary text-contrast-primary"
                        ></span>
                        <span
                          v-else
                          :data-tint="value.bg_color"
                          class="flex h-5 w-5 flex-row items-center justify-center rounded bg-primary text-contrast-primary"
                        >
                          <FontAwesomeIcon
                            :icon="['fal', value.icon]"
                            size="sm"
                          />
                        </span>
                      </span>
                      <span
                        class="flex-1"
                        :class="[
                          selected ? 'font-medium' : 'font-normal',
                          capitalize ? 'capitalize-first' : ''
                        ]"
                        v-html="value[displayKey]"
                      >
                      </span>
                    </span>
                  </slot>
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    <FontAwesomeIcon :icon="['fas', 'check']" />
                  </span>
                  <span
                    v-if="
                      favorite !== null && favorite.includes(value[trackBy])
                    "
                    :class="[
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    ]"
                  >
                    <FontAwesomeIcon :icon="['fas', 'star-of-life']" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </Float>
        </Listbox>
      </div>
    </div>
    <ErrorContainer :error-key="name" :errors="errors" />
  </div>
</template>

<script>
import { computed } from 'vue'
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { Float } from '@headlessui-float/vue'
import BaseLabelTop from '@c/addf-package/components/BaseLabel/BaseLabelTop.vue'

export default {
  name: 'SelectSimple2Component',
  components: {
    BaseLabelTop,
    Float,
    ErrorContainer,
    FontAwesomeIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'normal'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    values: {
      type: Array,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    displayKey: {
      type: String,
      required: false,
      default: 'name'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    selectedLabel: {
      type: String,
      required: false,
      default: null
    },
    noneSelectedLabel: {
      type: String,
      required: false,
      default: null
    },
    numberDisplayed: {
      type: Number,
      required: false,
      default: 1
    },
    includeSelectAllOption: {
      type: Boolean,
      required: false,
      default: true
    },
    favorite: {
      type: Array,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true
    },
    isFeminine: {
      type: Boolean,
      required: false,
      default: false
    },
    colored: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValues = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    return { selectedValues }
  },
  computed: {
    orderedValues() {
      if (
        this.favorite === null ||
        !Array.isArray(this.favorite) ||
        this.favorite.length === 0 ||
        !this.favoriteOnTop
      ) {
        return this.values
      }
      return this.values.sort((a, b) =>
        this.favorite.includes(a.id) ? -1 : this.favorite.includes(b.id) ? 1 : 0
      )
    },
    selectedValuesLabel() {
      let no_selection_trad = this.isFeminine
        ? this.$t('global.aucun_selectionne_f')
        : this.$t('global.aucun_selectionne')
      var find = this.values?.find(
        (item) => item[this.trackBy] === this.modelValue
      )
      return find ? find[this.displayKey] : no_selection_trad
    },
    selectedValuesBgColor() {
      var find = this.values.find(
        (item) => item[this.trackBy] === this.modelValue
      )
      return find?.bg_color ?? find?.tint ?? 'primary'
    },
    selectedValuesIcon() {
      var find = this.values.find(
        (item) => item[this.trackBy] === this.modelValue
      )
      return find ? find.icon : null
    },
    sizeClass() {
      switch (this.size) {
        case 'small':
          return 'py-1.5'
        case 'normal':
          return 'py-2'
      }
    }
  }
}
</script>

<style scoped></style>
