/* eslint-disable camelcase */
import apiClient from '@u/apiClient'
// import router from '@/router'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  // region fetch
  async fetchBankAccountList({ commit }) {
    await apiClient
      .get('bank-account')
      .then(async (response) => {
        await commit('setBankAccountList', response.data.bank_accounts)
        await commit('setBankAccountMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchBankAccountList', reason)
      })
  },
  async fetchBankAccountById({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`bank-account/${payload.id}`)
      .then(async (response) => {
        await commit('setBankAccount', response.data.bank_account)
        await commit('setBankAccountMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchBankAccountById', reason)
      })
  },
  async fetchNewBankAccount({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=BankAccount')
      .then(async (response) => {
        await commit('setBankAccount', response.data.BankAccount)
        await commit('setBankAccountMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewBankAccount', reason)
      })
  },
  fetchBankAccountActivityByBankAccountID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`bank-account/${payload.bank_account_id}/activities`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region store
  async storeBankAccount({ commit }, payload) {
    await apiClient
      .post('bank-account', payload.bank_account)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
          await commit('setBankAccountList', response.data.bank_accounts)
          await commit('setBankAccountMetadata', response.data.metadata)
        }
      })
      .catch(async (reason) => {
        console.error('storeBankAccount', reason)
      })
  },
  // endregion

  // region update
  async updateBankAccount({ commit }, payload) {
    await apiClient
      .put(`bank-account/${payload.id}`, payload.bank_account)
      .then(async (response) => {
        if (response.status === 201) {
          processSuccessCallback(payload)
          let bank = null
          if (payload.single_bank_account) {
            bank = response.data.bank_accounts.find(
              (bank) => bank.id === payload.id
            )
            await commit('setBankAccountList', bank)
            await commit('setBankAccountMetadata', response.data.metadata)
          } else {
            await commit('setBankAccountList', response.data.bank_accounts)
          }
        }
      })
      .catch(async (reason) => {
        console.error('updateBankAccount', reason)
      })
  },
  // endregion

  // region delete
  async deleteBankAccountById({ commit }, payload) {
    await apiClient
      .delete(`bank-account/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setBankAccountList', response.data.bank_accounts)
          await commit('setBankAccountMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteBankAccountById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteBankAccountById({ commit }, payload) {
    await apiClient
      .get(`bank-account/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setBankAccountList', response.data.bank_accounts)
          await commit('setBankAccountMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteBankAccountById', reason)
      })
  }
  // endregion
}
