const TheLocationIndexPage = () =>
  import('@/views/settings/corporation/location/index/TheLocationIndexPage')
const TheLocationFormsTabComponent = () =>
  import(
    '@/views/settings/corporation/location/form/TheLocationFormsTabComponent'
  )
const TheLocationInformationPage = () =>
  import(
    '@/views/settings/corporation/location/form/tabs/information/TheLocationInformationPage'
  )
const TheLocationActivityPage = () =>
  import(
    '@/views/settings/corporation/location/form/tabs/activities/TheLocationActivityPage'
  )

export const settingsLocationRoutes = [
  {
    path: 'location',
    children: [
      {
        path: '',
        name: 'settings_list_location_route',
        component: TheLocationIndexPage,
        meta: {
          type: 'index',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: true }
          ]
        }
      },
      {
        path: ':id',
        name: 'settings_edit_location_information_route',
        props: true,
        component: TheLocationFormsTabComponent,
        children: [
          {
            path: '',
            component: TheLocationInformationPage,
            name: 'settings_edit_location_route'
          },
          {
            path: 'activities',
            component: TheLocationActivityPage,
            name: 'settings_edit_location_activity_route',
            props: true
          }
        ],
        meta: {
          type: 'edit',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_company_title', active: false },
            { title: 'content_company_edit_title', active: true }
          ]
        }
      },
      {
        path: 'new',
        name: 'settings_create_location_info_route',
        component: TheLocationFormsTabComponent,
        children: [
          {
            path: '',
            component: TheLocationInformationPage,
            name: 'settings_create_location_route'
          }
        ],
        meta: {
          type: 'create',
          breadcrumb: [
            { title: 'content_title', active: false },
            { title: 'content_implantation_title', active: false },
            { title: 'content_implantation_new_title', active: true }
          ]
        }
      }
    ]
  }
]
