<template>
  <div class="h-full">
    <ActivityContainer :activities="activities" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ActivityContainer from '@c/Activity/ActivityContainer.vue'

export default {
  name: 'TheBankAccountActivityPage',
  components: {
    ActivityContainer
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activities: []
    }
  },
  mounted() {
    this.fetchBankAccountActivities()
  },
  methods: {
    ...mapActions({
      processFetchBankAccountActivitiesAction:
        'bankAccount/fetchBankAccountActivityByBankAccountID'
    }),
    fetchBankAccountActivities() {
      this.processFetchBankAccountActivitiesAction({
        bank_account_id: this.id
      }).then((response) => {
        this.activities = response.activities
      })
    }
  }
}
</script>
