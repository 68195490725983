import apiClient from '@u/apiClient'

export default {
  // region fetch
  async fetchElectronicSignature({ commit }, payload) {
    return apiClient
      .get('/electronic-signature', {
        params: payload
      })
      .then((response) => response.data)
  },
  async fetchCountElectronicSignature({ commit }, payload) {
    return apiClient
      .get('/electronic-signature/count')
      .then((response) => response.data)
  },
  fetchElectronicSignatureById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(`/electronic-signature/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion
  // region store
  // endregion
  // region update
  // endregion
  //region delete
  deleteMediaFromEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(
          `/electronic-signature/${payload.electronic_signature_id}/media/${payload.media_id}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  deleteElectronicSignature({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .delete(`/electronic-signature/${payload.electronic_signature_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  restoreMediaFromEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(
          `/electronic-signature/${payload.electronic_signature_id}/media/${payload.media_id}/undo-delete`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion
  addPdfToEnvelope({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post('/electronic-signature/add-to-envelope', payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  sendToYouSign({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `/electronic-signature/${payload.electronic_signature_id}/send-to-yousign`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  syncToYouSign({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `/electronic-signature/${payload.electronic_signature_id}/sync-to-yousign`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  addSignersToYouSign({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .post(
          `/electronic-signature/${payload.electronic_signature_id}/add-signers-to-yousign`,
          payload.params
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getOngoingElectronicSignature({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(
          `/electronic-signature/${payload.electronic_signature_id}/get-ongoing-electronic-signature`,
          payload.params
        )
        .then((response) => {
          console.log(response)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  storeSignedElectronicSignature({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get(
          `/electronic-signature/${payload.electronic_signature_id}/store-signed-electronic-signature/${payload.media_id}`,
          payload.params
        )
        .then((response) => {
          console.log(response)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
