const TheElectronicSignaturePage = () =>
  import('@/views/electronicSignature/ElectronicSignaturePage.vue')

const TheElectronicSignatureIndexPage = () =>
  import('@/views/electronicSignature/index/ElectronicSignatureIndex.vue')

const TheElectronicSignatureViewPage = () =>
  import('@/views/electronicSignature/form/ElectronicSignatureView.vue')

export const electronicSignatureRootPages = [
  {
    path: 'electronic-signature',
    name: 'electronic_signature_root_route',
    component: TheElectronicSignaturePage,
    props: true,
    children: [
      // {
      //   path: '',
      //   name: 'electronic_signature_index_route',
      //   component: TheElectronicSignatureIndexPage,
      //   props: false
      // },
      {
        path: ':electronicSignatureId',
        name: 'electronic_signature_view_route',
        component: TheElectronicSignatureViewPage,
        props: true
      }
    ]
  }
]
