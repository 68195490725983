<template>
  <div class="fm-form-input dark:bg-slate-700" :class="[label ? 'mt-2' : '']">
    <label v-if="label">
      {{ label }}
      <span v-if="additionalInformation" class="ml-2">
        ({{ additionalInformation }})
      </span>
    </label>
    <div
      class="flex w-full flex-row items-center overflow-hidden rounded-md border-0 p-0"
    >
      <div
        v-if="$slots.prepend"
        class="flex flex-shrink-0 items-center justify-center text-base"
      >
        <slot name="prepend"></slot>
      </div>
      <input
        v-if="masked"
        v-mask="mask"
        type="text"
        readonly
        :value="modelValue"
        :class="$attrs.class"
      />
      <input
        v-else
        type="text"
        readonly
        :class="[sizeClass, $attrs.class]"
        :value="modelValue"
      />
      <div
        v-if="$slots.append"
        class="flex flex-shrink-0 items-center justify-center text-base"
      >
        <slot name="append"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseShowLabel',
  props: {
    additionalInformation: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    isVertical: {
      type: Boolean,
      required: false,
      default: false
    },
    mask: {
      type: [String, null],
      required: false,
      default: null
    },
    masked: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideModelValue: {
      type: Boolean,
      default: false
    },
    displayOnlyLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    displayedValue() {
      if (this.displayOnlyLabel) {
        return null
      } else {
        if (this.hideModelValue) {
          return this.modelValue === null || this.modelValue === ''
            ? '-'
            : '**********'
        } else {
          return this.modelValue === null ||
            this.modelValue === '' ||
            this.modelValue === undefined
            ? '-'
            : this.modelValue
        }
      }
    },
    sizeClass() {
      switch (this.size) {
        case 'extra-small':
          return '!px-2 !py-1 text-xs'
        case 'small':
          return '!px-3 !py-1.5 text-sm'
        case 'large':
          return '!px-5 !py-3'
        case 'medium':
        default:
          return '!px-3 !py-2'
      }
    }
  }
}
</script>
