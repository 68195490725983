<template>
  <Popover>
    <Float
      v-if="user"
      placement="top-start"
      transition-name="slide-fade-from-bottom"
    >
      <PopoverButton
        class="dropdown-toggle group block w-full flex-shrink-0 focus:outline-none"
      >
        <div class="flex items-center">
          <div>
            <UserAvatar :user-id="user.id" hide-status />
          </div>
          <div class="ml-3 hidden @3xs/menu:block">
            <p
              v-show="!collapsedMenu"
              class="truncate font-bold text-white"
              :class="{
                'text-sm': user.full_name?.length >= 25,
                'text-base': user.full_name?.length >= 15
              }"
            >
              {{ user.full_name }}
            </p>
            <p
              class="hidden text-xs font-medium text-indigo-200 group-hover:text-white"
            >
              View profile
            </p>
          </div>
        </div>
      </PopoverButton>

      <PopoverPanel class="z-10 mb-3 w-56 px-4 sm:px-0" v-slot="{ close }">
        <div class="box dark:bg-gray-700">
          <div
            class="border-b border-black border-opacity-5 p-4 dark:border-slate-700"
          >
            <div class="font-medium">
              {{ user.full_name }}
            </div>
            <div class="mt-0.5 text-xs text-gray-600 dark:text-gray-600">
              {{ user.email }}
            </div>
            <div class="mt-0.5 text-xs text-gray-600 dark:text-gray-600">
              {{ $t('global.version') }}: {{ releaseVersion }}
            </div>
            <div
              class="-mx-1 mt-0.5 cursor-pointer rounded px-1 text-xs text-sky-600 capitalize-first hover:bg-gray-200 dark:text-gray-600"
              @click="
                $router.push({ name: 'settings_version_note_index_route' })
              "
            >
              {{ $t('global.see_all_version_note') }}
            </div>
          </div>

          <div class="p-2">
            <RouterLink
              :to="{ name: 'profile_form_route' }"
              class="block flex items-center rounded-md p-2 transition duration-300 ease-in-out capitalize-first hover:bg-gray-200 dark:hover:bg-slate-400"
            >
              <FontAwesomeIcon :icon="['fal', 'user']" class="mr-2" />
              <div class="inline-block capitalize-first">
                {{ $t(`global.my_profile`) }}
              </div>
            </RouterLink>
          </div>

          <div
            class="dark:bg-slate-700rounded-md flex flex-row items-center border-t border-black border-opacity-5 p-2 dark:hover:bg-slate-400"
          >
            <div class="mx-2 flex flex-1 flex-row items-center gap-2">
              <h2 class="flex shrink-0 flex-row justify-center">
                <FontAwesomeIcon fixed-width :icon="['fal', 'swatchbook']" />
              </h2>
              <div class="flex-1">
                <ThemeSelector v-model:theme="selectedTheme" />
              </div>
            </div>
          </div>

          <div
            v-if="$can('manage_dossier_form')"
            class="border-t border-black border-opacity-5 p-2 dark:border-slate-700"
          >
            <button
              class="flex w-full items-center gap-2 rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-slate-400"
              @click="() => toggleGlobalForm(close)"
            >
              <FontAwesomeIcon :icon="['fal', 'list-check']" class="mr-2" />
              <div class="inline-block capitalize-first">
                {{ $t('global.form_editor') }}
              </div>
            </button>
          </div>
          <div
            class="border-t border-black border-opacity-5 p-2 dark:border-slate-700"
          >
            <TheDarkModeSwitcher2 />
          </div>
          <div
            class="border-t border-black border-opacity-5 p-2 dark:border-slate-700"
          >
            <a
              href=""
              class="flex items-center gap-2 rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-slate-400"
              @click.prevent="() => toggleOpenModalQRCode(close)"
            >
              <FontAwesomeIcon :icon="['fal', 'qrcode']" fixed-width />
              <span>{{ $t('global.mobile_connection') }}</span>
            </a>
          </div>
          <div
            class="border-t border-black border-opacity-5 p-2 dark:border-slate-700"
          >
            <a
              href=""
              class="flex items-center gap-2 rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-slate-400"
              @click.prevent="handleLogout"
            >
              <FontAwesomeIcon fixed-width :icon="['fal', 'sign-out']" />
              <span>{{ $t('global.user_logout') }}</span>
            </a>
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>

  <AuthQRCodeGeneratorComponent
    v-model:opened="qrcodeModalOpened"
    @action:close="() => toggleOpenModalQRCode(close)"
  />
</template>
<script>
import TheDarkModeSwitcher2 from '@/views/layouts/dark-mode-switcher/TheDarkModeSwitcher2'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ThemeSelector from '@c/ThemeSelector.vue'
import AvatarComponent from '@c/AvatarComponent.vue'
import { Float } from '@headlessui-float/vue'
import UserAvatar from '@c/Avatar/UserAvatar.vue'
import AuthQRCodeGeneratorComponent from '@c/Auth/AuthQRCodeGeneratorComponent.vue'

export default {
  name: 'InfoUserModalComponent',
  components: {
    AuthQRCodeGeneratorComponent,
    UserAvatar,
    Float,
    AvatarComponent,
    ThemeSelector,
    FontAwesomeIcon,
    TheDarkModeSwitcher2,
    Popover,
    PopoverButton,
    PopoverPanel
  },
  data() {
    return {
      selectedTheme: null,
      qrcodeModalOpened: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      releaseVersion: 'metadata/getReleaseVersion'
    }),
    ...mapGetters('sideMenu', ['collapsedMenu'])
  },
  watch: {
    selectedTheme(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$h.setTheme(newValue)
      }
    }
  },
  mounted() {
    this.selectedTheme = localStorage.getItem('theme') ?? 'blue'
  },
  methods: {
    ...mapActions({
      fetchMetadata: 'metadata/fetchAllMetadata'
    }),
    ...mapMutations({
      toggleGlobalFormEditionMode: 'metadata/toggleGlobalFormEditionMode'
    }),
    toggleGlobalForm(close) {
      this.toggleGlobalFormEditionMode()
      close()
    },
    refreshAuthHandler() {
      this.$store.dispatch('auth/refreshAuth')
    },
    async handleLogout() {
      await this.$store.dispatch('auth/logout')
    },
    toggleOpenModalQRCode(close) {
      this.qrcodeModalOpened = !this.qrcodeModalOpened

      if (this.qrcodeModalOpened) {
        close()
      }
    }
  }
}
</script>

<style>
.slide-fade-from-bottom-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-from-bottom-leave-active {
  transition: all 0.3s ease-in;
}

.slide-fade-from-bottom-enter-from,
.slide-fade-from-bottom-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
