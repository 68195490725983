import apiClient from '@u/apiClient'

export default {
  // region fetch
  getCremagestAvaiability({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('/partners/cremagest/availability')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchEnaosPlacesByWikiCity({ commit }, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      apiClient
        .get(`partner/enaos/places`, { params: payload })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
