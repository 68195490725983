<template>
  <div class="flex flex-row rounded border p-4">
    <div class="w-48">
      <h3 class="text-2xl font-bold">{{ release.version }}</h3>
      <p>{{ $h.formatDate(release.date, 'LL') }}</p>
    </div>
    <div class="flex flex-1 flex-col gap-5">
      <template v-for="type in releaseNoteTypes" :key="type.id">
        <div
          class="flex flex-col gap-2"
          v-if="!!releaseNotesgrouped[type.id]?.length"
        >
          <h3 class="mx-5 border-b text-xl font-bold">{{ type.name }}</h3>
          <ul class="ml-10 list-disc">
            <li
              v-for="release_note in releaseNotesgrouped[type.id]"
              :key="release_note.id"
              class="mb-1 last:mb-0"
            >
              <h4 class="font-semibold">
                {{ release_note.title[$i18n.locale] }}
              </h4>
              <p class="text-xs">
                {{ release_note.description[$i18n.locale] }}
              </p>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReleaseComponent',
  props: {
    release: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      releaseNoteTypes: 'metadata/getReleaseNoteTypes'
    }),
    releaseNotesgrouped() {
      return Object.groupBy(
        this.release.active_release_notes,
        (release_note) => release_note.type
      )
    }
  }
}
</script>
