import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

// const base_errors = { deceased: {}, events: [] }
const base_errors = {}
export default {
  updateDossierInfos: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/update-infos/${payload.dossier_id}`, payload.dossier)
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierContacts: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/update-contacts/${payload.dossier_id}`, payload.dossier)
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierBurial: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/update-burial/${payload.dossier_id}`, payload.dossier)
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierOtherEvents: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `dossier/update-other-events/${payload.dossier_id}`,
          payload.dossier
        )
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierRemains: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/update-remains/${payload.dossier_id}`, payload.dossier)
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierInsurance: ({ commit }, payload) => {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/update-insurance/${payload.dossier_id}`, payload.dossier)
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierParticularities({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .put(
          `/dossier/update-particularity/${payload.dossier_id}`,
          payload.dossier
        )
        .then((response) => {
          if (response.status === 200) {
            commit('setDossier', response.data.dossier)
            commit('setDossierMetadata', response.data.metadata)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateDossierSiteInfos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`dossier/${payload.dossier.id}/site-infos`, payload.dossier)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async updateDossierTasks({ commit }, payload) {
    await apiClient
      .put(`dossier/update-task/${payload.dossier_id}`, payload.dossier)
      .then(async (response) => {
        if (response.status === 200) {
          commit('auth/setErrors', base_errors, { root: true })
          commit('setDossier', response.data.dossier)
          commit('setDossierMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        await console.error('updateDossierParticularities', reason)
      })
  }
}
