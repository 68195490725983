<template>
  <div class="h-full w-full bg-gray-100 p-4">
    <div class="flex h-full flex-col gap-5 xxl:mx-auto xxl:max-w-4xl">
      <div class="flex shrink-0 flex-col gap-2">
        <div class="flex shrink-0 flex-row">
          <h3 class="flex-1 text-2xl font-bold capitalize-first">
            {{ $t('global.activities') }}
          </h3>
          <div class="flex shrink-0 flex-row">
            <UserAvatarPopover
              v-for="causer_id in causerIds"
              :key="causer_id"
              :user-id="causer_id"
            />
          </div>
        </div>
        <div v-if="!hideFilter" class="flex flex-row gap-3 empty:hidden">
          <div class="w-1/5">
            <BaseShowEditDatePickerv2
              v-model:date="filter.date"
              edition-mode
              :label="$t('attributes.date')"
              :extra-dots="activityDates"
            />
          </div>
          <div v-if="usersForFilter.length > 1" class="w-2/5">
            <SelectComponent
              v-model="filter.users"
              :values="usersForFilter"
              :label="$t('attributes.users')"
              multiple
            />
          </div>
          <div v-if="eventForFilter.length > 1" class="w-2/5">
            <SelectComponent
              v-model="filter.descriptions"
              :values="eventForFilter"
              :label="$t('global.events')"
              multiple
            />
          </div>
        </div>
      </div>
      <div class="flex min-h-0 flex-1 flex-col gap-3 overflow-y-auto">
        <ActivityBlock
          v-for="activity in filteredActivities"
          :key="activity.id"
          :activity="activity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatarPopover from '@c/Avatar/Popover/UserAvatarPopover.vue'
import ActivityBlock from '@c/Activity/ActivityBlock.vue'
import { mapGetters } from 'vuex'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'

export default {
  name: 'ActivityContainer',
  components: {
    BaseShowEditDatePickerv2,
    SelectComponent,
    ActivityBlock,
    UserAvatarPopover
  },
  data() {
    return {
      filter: {
        users: [],
        descriptions: [],
        date: null
      }
    }
  },
  props: {
    activities: {
      type: Array,
      required: true
    },
    hideFilter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getAllUserMetadata'
    }),
    filteredActivities() {
      return this.activities.filter((activity) => {
        return (
          (this.filter.users.length == 0 ||
            this.filter.users.includes(activity.causer_id)) &&
          (this.filter.descriptions.length == 0 ||
            this.filter.descriptions.includes(activity.description)) &&
          (this.filter.date === null ||
            activity.created_at.includes(`${this.filter.date}T`))
        )
      })
    },
    causerIds() {
      return [...new Set(this.activities.map((activity) => activity.causer_id))]
    },
    descriptionIds() {
      return [
        ...new Set(this.activities.map((activity) => activity.description))
      ]
    },
    usersForFilter() {
      return this.causerIds.map((id) => {
        return {
          id: id,
          name: this.users(id)?.full_name
        }
      })
    },
    eventForFilter() {
      return this.descriptionIds.map((description) => {
        return {
          id: description,
          name: this.$t(`enums.activityLog.type.${description}`)
        }
      })
    },
    activityDates() {
      return [
        ...new Set(
          this.activities.map((activity) => {
            return activity.created_at.split('T')[0]
          })
        )
      ].map((date) => {
        return {
          color: 'orange',
          date: date
        }
      })
    }
  }
}
</script>
