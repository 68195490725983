<template>
  <div class="flex h-full p-1">
    <div v-if="current_vue === view.Actions" class="flex flex-1 flex-col gap-2">
      <div class="flex flex-1 flex-col gap-3">
        <div
          v-for="(innerMenuOption, idx) in menuOptions"
          :key="idx"
          class="flex flex-col gap-1"
        >
          <div v-for="(menuOption, idx2) in innerMenuOption" :key="idx2">
            <BaseButton
              v-if="menuOption.gate"
              :icon="menuOption.icon"
              :title="menuOption.label"
              inverse-icon-order
              color="primary"
              @click="menuOption.action"
              block
            />
          </div>
        </div>
        <div>
          <BaseButton
            :icon="'cogs'"
            color="secondary"
            :title="$t('global.printing_options')"
            inverse-icon-order
            @click="current_vue = 'form'"
            block
          />
        </div>
        <div v-if="headers.length">
          <BaseButton
            :icon="'file-invoice'"
            :title="$t('global.header')"
            color="secondary"
            inverse-icon-order
            @click="current_vue = 'header'"
            block
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="[view.Form, view.Header].includes(current_vue)"
      class="flex flex-1 flex-col gap-4"
    >
      <div class="flex min-h-0 flex-1">
        <div class="scrollbar-custom flex flex-1 flex-col gap-6 overflow-auto">
          <template v-if="current_vue === view.Form">
            <div class="flex flex-row items-center gap-x-2">
              <span class="shrink-0">
                <BaseButton
                  icon="chevron-left"
                  size="extra-small"
                  @click="current_vue = 'actions'"
                />
              </span>
              <h2 class="flex-1 text-xl font-bold capitalize-first">
                {{ $t(`enums.invoicing.docTypes.${invoice.doc_type}`) }}
              </h2>
            </div>
            <InvoicingPrintLeftMenuSection>
              <TheInvoicingPrintInvoiceForm
                v-model:print-settings="localPrintSettings"
                :covering-letters="coveringLetters"
              />
            </InvoicingPrintLeftMenuSection>

            <InvoicingPrintLeftMenuSection :label="$t('global.options')">
              <TheInvoicingPrintOptionsForm
                v-model:print-settings="localPrintSettings"
              />
            </InvoicingPrintLeftMenuSection>
          </template>
          <template v-else-if="current_vue === view.Header">
            <HeaderSelector
              v-model:header="printSettings.header"
              v-model:current-view="current_vue"
              :header-list="headers"
              @change="current_vue = view.Actions"
            />
          </template>
        </div>
      </div>
      <div class="flex shrink-0 flex-row justify-between">
        <BaseButton
          v-if="current_vue === view.Form"
          :icon="'file-invoice'"
          :title="$t('attributes.header')"
          size="small"
          color="light"
          @click="current_vue = view.Header"
        />
        <BaseButton
          v-if="current_vue === view.Header"
          class-name="bg-white"
          text-color="txt-gray-700"
          :icon="'list'"
          size="small"
          @click="current_vue = view.Form"
        />
        <BaseButton
          v-if="params_changed"
          theme-color="bg-secondary text-contrast-secondary"
          :title="$t('global.refresh_document')"
          icon="arrows-rotate"
          size="small"
          @click="() => handlerRefreshDocument()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InvoicingPrintLeftMenuSection from '@/views/invoicing/show/print/components/leftMenu/components/InvoicingPrintLeftMenuSection'
import TheInvoicingPrintInvoiceForm from '@/views/invoicing/show/print/components/leftMenu/components/TheInvoicingPrintInvoiceForm'
import TheInvoicingPrintOptionsForm from '@/views/invoicing/show/print/components/leftMenu/components/TheInvoicingPrintOptionsForm'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import HeaderSelector from '@c/Media/HeaderSelector.vue'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import BaseShowEditSwitch from '@c/BaseShowEditSwitch.vue'
import eventType from '@/assets/enums/events/EventType'

export default {
  name: 'TheInvoicingPrintLeftMenu',
  components: {
    BaseShowEditSwitch,
    SelectLanguages,
    HeaderSelector,
    BaseButton,
    TheInvoicingPrintOptionsForm,
    TheInvoicingPrintInvoiceForm,
    InvoicingPrintLeftMenuSection
  },
  props: {
    printSettings: {
      type: Object,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    coveringLetters: {
      type: Array,
      required: false,
      default: () => []
    },
    menuOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['refresh-document', 'update:printSettings'],
  data() {
    return {
      document_header: null,
      view: {
        Form: 'form',
        Header: 'header',
        Actions: 'actions'
      },
      current_vue: 'actions',
      params_changed: false
    }
  },
  computed: {
    eventType() {
      return eventType
    },
    localPrintSettings: {
      get() {
        return this.printSettings
      },
      set(value) {
        this.$emit('update:printSettings', value)
      }
    }
  },
  watch: {
    printSettings: {
      handler(newValue, oldValue) {
        this.params_changed = true
      },
      deep: true
    }
  },
  mounted() {
    // this.current_vue = this.view.Form
  },
  methods: {
    handlerRefreshDocument() {
      this.params_changed = false
      this.$emit('refresh-document')
    }
  }
}
</script>
