import apiClient from '@u/apiClient'

export default {
  getCompanyChiftConsumer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/chift/consumer`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createCompanyChiftConsumer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`company/${payload.id}/chift/consumer`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  searchCustomerInChiftAccounting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/chift/search-customer`, {
          params: payload.params
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  deleteCompanyChiftConsumer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`company/${payload.id}/chift/consumer`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getCompannyChiftIntegretionsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/chift/integrations-list`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  addConnexionToConsumer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`company/${payload.id}/chift/connexion`, payload.params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  syncAccountingCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/chift/sync-accounting-code`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  getCompanyChiftConnexionMetadata({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`company/${payload.id}/chift/connexion-metadata`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  deleteCompanyChiftConnexion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`company/${payload.id}/chift/connexion/${payload.connexion_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
