/* eslint-disable camelcase */
import apiClient from '@/utils/apiClient'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

function errorSuccessCallback(payload) {
  if (payload.errorCallback) {
    payload.errorCallback()
  }
}

export default {
  // region fetch
  async fetchAllUser({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('user')
      .then(async (response) => {
        await commit('setUserList', response.data.users)
        await commit('setUserMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchAllUser', reason)
      })
  },

  async fetchUserByID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`user/${payload.id}`)
      .then(async (response) => {
        await commit('setUser', response.data.user)
        await commit('setUserMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchUserByID', reason)
      })
  },

  async fetchNewUser({ commit }) {
    commit('setUser', {})
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('model/new?model=user')
      .then(async (response) => {
        await commit('setUser', response.data.user)
        await commit('setUserMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchNewUser', reason)
      })
  },
  fetchUserActivitiesByUserID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`user/${payload.user_id}/activities`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  fetchUserTokensByUserID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`user/${payload.user_id}/tokens`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  createUserTokensByUserID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .post(`user/${payload.user_id}/token`, payload.data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  deleteUserTokensByUserID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`user/${payload.user_id}/token/${payload.token_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  //region export items
  async fetchAllUserForExport({ commit }, payload) {
    await apiClient
      .post('/user/users-export')
      .then(async (response) => {
        window.open(response.data.url)
        processSuccessCallback(payload)
      })
      .catch((reason) => {
        console.error('errors', reason)
        commit('auth/setErrors', reason.response, { root: true })
      })
  },
  //endregion

  //region import items
  async importListUsers({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('file', payload.file)
    await apiClient
      .post(`/user/users-import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            commit('setErrorImport', response.data.errors)
            errorSuccessCallback(payload)
          } else if (response.data.users) {
            await commit('setUserList', response.data.users)
            await commit('setUserMetadata', response.data.metadata)
            processSuccessCallback(payload)
          }
        }
      })
      .catch(async (reason) => {
        await console.error('importListUsers', reason)
      })
  },
  //endregion

  // region store
  storeNewUser: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.user))
    if (payload.user.new_avatar_file) {
      formData.append('new_avatar_file', payload.user.new_avatar_file)
    }
    if (payload.user.new_signature_file) {
      formData.append('new_signature_file', payload.user.new_signature_file)
    }

    return new Promise((resolve, reject) => {
      apiClient
        .post('user', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  addUserAvatar: ({ commit }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })
    let formData = new FormData()
    formData.append('avatar', payload.new_avatar_file)

    return new Promise((resolve, reject) => {
      apiClient
        .post(`user/${payload.user_id}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  getUserById: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`user/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  // region update
  updateUser: ({ commit, dispatch }, payload) => {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('data', JSON.stringify(payload.user))
    if (payload.user.new_avatar_file) {
      formData.append('new_avatar_file', payload.user.new_avatar_file)
    }
    if (payload.user.new_signature_file) {
      formData.append('new_signature_file', payload.user.new_signature_file)
    }
    formData.append('_method', 'PUT')

    return new Promise(async (resolve, reject) => {
      apiClient
        .post(`user/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async (response) => {
          if (response.status === 201) {
            commit('setUser', response.data.user)
            commit('metadata/incrementMainKey', null, { root: true })
            resolve(response.data)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },

  // endregion

  // region delete
  async deleteUserById({ commit }, payload) {
    await apiClient
      .delete(`user-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setUserList', response.data.users)
          await commit('setUserMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteUserById', reason)
      })
  },
  deleteUserAvatar: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .delete(`user/${payload.user_id}/avatar`)
        .then(async (response) => {
          resolve(response.data)
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region undoDelete
  async undoDeleteUserById({ commit }, payload) {
    await apiClient
      .get(`user/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          await commit('setUserList', response.data.users)
          await commit('setUserMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteUserById', reason)
      })
  }
  // endregion
}
