<template>
  <div class="grid grid-cols-1 gap-y-2">
    <div>
      <SelectLanguages
        v-model="localPrintSettings.language"
        :label="$t('attributes.language')"
        edition-mode
        customer-language-only
        required
      />
    </div>

    <div class="grid grid-cols-2 gap-y-2">
      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.recto_verso"
            :label="$t('attributes.recto_verso')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.nb_page"
            :label="$t('attributes.nb_page')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.general_conditions"
            :label="$t('attributes.general_conditions')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.payment_historic"
            :label="$t('attributes.payments')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.electronic_signature"
            :label="$t('attributes.electronic_signature')"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <div>
          <WalkmanButton
            v-model="localPrintSettings.qrcode_tracking"
            label="QRcode tracking"
            :options="[
              { value: true, label: $t('enums.yesNo.1') },
              { value: false, label: $t('enums.yesNo.0') }
            ]"
            size="small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WalkmanButton from '@c/WalkmanButton'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'

export default {
  name: 'TheInvoicingPrintOptionsForm',
  components: {
    SelectLanguages,
    WalkmanButton
  },
  props: {
    printSettings: {
      type: Object,
      required: true
    }
  },
  emits: ['update:printSettings'],
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors',
      initialMetadata: 'metadata/getInitialMetadata',
      auth: 'auth/getAuth'
    }),
    localPrintSettings: {
      get() {
        return this.printSettings
      },
      set(value) {
        this.$emit('update:printSettings', value)
      }
    }
  }
}
</script>
