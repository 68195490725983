
<template>
  <div class="flex flex-col gap-3">
    <div v-if="default_country === Country.FRANCE" class="flex flex-row gap-6">
      <div class="shrink-0">
        <WalkmanButton
          v-model="localPrintSettings.normalized"
          :label="$t('global.invoice_normalised')"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
        />
      </div>
      <div v-if="localPrintSettings.normalized" class="flex-1">
        <BaseShowEditInput
          v-model="localPrintSettings.nb_normalized"
          :label="$t('attributes.nb_summary')"
        />
      </div>
    </div>

    <div class="flex flex-row gap-6">
      <div class="shrink-0">
        <WalkmanButton
          v-model="localPrintSettings.summary"
          :label="$t('invoicing.summary')"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
        />
      </div>
      <div v-if="localPrintSettings.summary" class="flex-1">
        <BaseShowEditInput
          v-model="localPrintSettings.nb_summary"
          :label="$t('attributes.nb_summary')"
        />
      </div>
    </div>

    <div class="flex flex-row gap-6">
      <div class="shrink-0">
        <WalkmanButton
          v-model="localPrintSettings.detailed"
          :label="$t('invoicing.detailed')"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
        />
      </div>
      <div v-if="localPrintSettings.detailed" class="flex-1">
        <BaseShowEditInput
          v-model="localPrintSettings.nb_detailed"
          :label="$t('attributes.nb_summary')"
        />
      </div>
    </div>

    <div v-if="localPrintSettings.detailed" class="-mt-1">
      <div>
        <button class="text-primary" @click="customDetailed = !customDetailed">
          {{ $t('global.customize') }}
        </button>
      </div>
      <div
        v-if="customDetailed"
        class="flex flex-col gap-2 rounded-lg bg-gray-100 p-2"
      >
        <div
          v-for="(category, index) in allInvoiceCategoriesPresentInTheInvoice"
          :key="index"
        >
          <WalkmanButton
            v-if="invoice_styling_types && localPrintSettings.category_settings[category]"
            v-model="localPrintSettings.category_settings[category].mode"
            :options="invoice_styling_types"
            track-by="id"
            attribute-label="name"
            size="small"
            :label="
              invoicing_categories.find((cat) => cat.id === category)?.name?.[
                $i18n.locale
              ] ?? '--'
            "
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <SelectComponent
        v-model="localPrintSettings.covering_letter"
        :label="$t('attributes.covering_letters')"
        name="covering_letter"
        :values="coveringLetters"
        track-by="id"
        display-key="name"
      />
    </div>
    <div class="flex flex-col">
      <SelectComponent
        v-model="localPrintSettings.payment_deposit_type"
        :label="$t('attributes.payment_deposit_type')"
        name="payment_deposit_type"
        :values="payment_deposit_types"
        track-by="id"
        display-key="name"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WalkmanButton from '@c/WalkmanButton'
import SelectSimpleComponent from '@c/BaseFormComponent/SelectSimpleComponent'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import { Country } from '@/assets/enums'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'TheInvoicingPrintInvoiceForm',
  components: {
    SelectComponent,
    BaseShowEditInput,
    SelectSimpleComponent,
    WalkmanButton
  },
  props: {
    printSettings: {
      type: Object,
      required: true
    },
    coveringLetters: {
      type: Array,
      required: true
    }
  },
  emits: ['update:printSettings'],
  data() {
    return {
      customDetailed: false
    }
  },
  computed: {
    Country() {
      return Country
    },
    ...mapGetters({
      errors: 'auth/getErrors',
      initialMetadata: 'metadata/getInitialMetadata',
      invoice: 'invoicing/getInvoice',
      defaultCountry: 'metadata/getDefaultCountry',
      invoice_styling_types: 'metadata/getInvoiceStylingTypesMetadata',
      invoicing_categories: 'metadata/getInvoicingCategoriesMetadata',
      message_texts: 'metadata/getInvoicingMessageTextsMetadata'
    }),
    localPrintSettings: {
      get() {
        return this.printSettings
      },
      set(value) {
        this.$emit('update:printSettings', value)
      }
    },
    default_country() {
      return this.invoice?.funeral_home?.address?.country ?? this.defaultCountry
    },
    allInvoiceCategoriesPresentInTheInvoice() {
      return [
        ...new Set(
          this.invoice?.invoice_items?.reduce((old, curr) => {
            old.push(curr.invoicing_category_id)
            return old
          }, [])
        )
      ]
    },
    payment_deposit_types() {
      return [
        { id: null, name: 'pas de RIB/QRCode' },
        ...this.initialMetadata?.enums?.payment_deposit_types
      ]
    }
  },
  watch: {
    'localPrintSettings.normalized'(newValue, oldValue) {
      if (!newValue) {
        if (
          !this.localPrintSettings.summary &&
          !this.localPrintSettings.detailed
        ) {
          this.localPrintSettings.detailed = true
        }
      }
    },
    'localPrintSettings.detailed'(newValue, oldValue) {
      if (!newValue) {
        if (
          !this.localPrintSettings.summary &&
          !this.localPrintSettings.normalized
        ) {
          this.localPrintSettings[
            this.default_country === Country.FRANCE ? 'normalized' : 'summary'
          ] = true
        }
      }
    },
    'localPrintSettings.summary'(newValue, oldValue) {
      if (!newValue) {
        if (
          !this.localPrintSettings.normalized &&
          !this.localPrintSettings.detailed
        ) {
          this.localPrintSettings[
            this.default_country === Country.FRANCE ? 'normalized' : 'detailed'
          ] = true
        }
      }
    }
  }
}
</script>
