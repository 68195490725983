const WikiCityFeesAndTaxesInformation = () =>
  import(
    '@/views/wiki/communes/form/tabs/feesAndTaxes/form/tabs/information/WikiCityFeesAndTaxesInformation.vue'
  )
const WikiCityFeesAndTaxesFormsTabsComponent = () =>
  import(
    '@/views/wiki/communes/form/tabs/feesAndTaxes/form/WikiCityFeesAndTaxesFormsTabsComponent.vue'
  )
const WikiCityFeesAndTaxesIndexPage = () =>
  import(
    '@/views/wiki/communes/form/tabs/feesAndTaxes/index/WikiCityFeesAndTaxesIndexPage.vue'
  )

export const wikiCityFeesAndTaxesRoutes = [
  {
    path: 'fees-and-taxes',
    children: [
      {
        path: '',
        name: 'wiki_edit_city_fees_and_taxes_page_route',
        component: WikiCityFeesAndTaxesIndexPage,
        meta: {
          pre_page: '',
          next_page: ''
        }
      },
      {
        path: ':fees_and_taxes_id',
        name: 'wiki_city_edit_fees_and_taxes_page_route',
        component: WikiCityFeesAndTaxesFormsTabsComponent,
        props: true,
        children: [
          {
            path: '',
            name: 'wiki_city_edit_fees_and_taxes_information_page_route',
            component: WikiCityFeesAndTaxesInformation
          }
        ],
        meta: {
          type: 'edit'
        }
      }
    ]
  }
]
