<template>
  <BaseCenteredModal
    v-model:is-modal-open="localVisible"
    :title="$t('global.errors') + ' (' + localRows.length + ')'"
    :click-outside-close="true"
  >
    <template #body>
      <div class="w-full flex flex-row items-start flex-wrap">
        <div v-for="(row, index) in localRows" :key="index" class="w-1/2">
          <div class="first-letter:uppercase">
            {{ $t('attributes.line') }} : {{ row.row + 1 }}
          </div>

          <div class="pl-3 w-full">
            <div
              v-for="(cell, indexCell) in row.cells"
              :key="indexCell"
              class="first-letter:uppercase text-red-600"
            >
              {{ $t('button.column') }} : {{ cell.title }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'

export default {
  name: 'ExcelErrorsModal',
  components: { BaseCenteredModal },
  props: {
    hotInstance: {
      type: Object,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:visible'],
  data() {
    return {}
  },
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    localRows() {
      return this.rows.sort((a, b) => a.row - b.row)
    }
  }
}
</script>
