import axios from 'axios'
import store from '../store'
import Bugsnag from '@bugsnag/js'
import { helper } from '@u/helper'

const ApiPort = helper.getDevPort()
const ApiUri = '/api/'

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:${ApiPort}${ApiUri}`
    : ApiUri

const apiClientDocument = axios.create({
  baseURL
})

apiClientDocument.interceptors.request.use(
  (config) => {
    if (
      localStorage.getItem('token') &&
      localStorage.getItem('expiration') &&
      new Date() > new Date(localStorage.getItem('expiration'))
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('expiration')
      window.location.href = '/connect'
    }
    if (localStorage.getItem('token')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default apiClientDocument
