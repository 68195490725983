const WorkInProgressComponent = () =>
  import('@/views/wip/WorkInProgressComponent.vue')

export const wikiLocationsRoute = [
  {
    path: 'wiki/locations',
    name: 'wiki_locations_page_route',
    component: WorkInProgressComponent
  }
]
